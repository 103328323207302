@use 'scss/config';
@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.checkbox-list {
}

.checkbox-list__item {
}

.checkbox-group {
}

.checkbox-field {
  margin-bottom: 24px;
}

.checkbox-field__label {
  &.text {
    display: inline-block;
    font-size: 12px;
    color: config.$color-input-label;
    line-height: 16px;
    margin-bottom: 8px;
  }
}

.checkbox {
  display: flex;
  flex-direction: column;

  .company-registration & {
    display: block;
  }

  .registration-form & {
    display: block;
  }
}

.checkbox__inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  padding-left: 32px;
  margin-bottom: 12px;
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  line-height: 24px;

  &.checkbox__inner--nowrap {
    flex-wrap: nowrap;
  }

  &.checkbox__inner--select {
    &.checkbox__inner--checked,
    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
}

.checkbox__input {
  @include helpers.visually-hidden;
}

.checkbox__label {
  position: relative;
}

.checkbox__row {
  display: block;
  margin-bottom: 10px;
}

.checkbox__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid config.$color-input-border-default;
  background-color: config.$color-white;
  border-radius: 2%;

  &:after {
    left: 9px;
    top: 5px;
    width: 3px;
    height: 7px;
    border: solid config.$color-white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: '';
    position: absolute;
    display: none;
  }

  .checkbox__inner:hover .checkbox__input ~ & {
    background-color: config.$color-stone;
  }

  .checkbox__inner .checkbox__input:checked ~ & {
    background-color: config.$color-input-border-hover;
    border-color: config.$color-input-border-hover;
  }

  .checkbox__inner .checkbox__input:checked ~ &:after {
    display: block;
  }
}

.checkbox__subfield {
  display: block;
  margin-top: 5px;
  margin-bottom: 20px;
  flex-basis: 100%;

  @include responsive.target(md, xl) {
    margin-top: 15px;
    margin-bottom: 40px;
  }
}
