@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.icon-link {
  display: inline-flex;
  align-items: center;
  color: config.$color-cod;
  text-decoration: none;
}

.icon-link__icon {
  font-family: config.$font-Icons;
  font-size: 18px;
  margin-right: 10px;
  margin-bottom: 2px;

  .icon-link--icon-box & {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
    background-color: config.$color-curry;
    color: config.$color-white;
  }

  @include responsive.target(md, xl) {
    font-size: 22px;
    margin-bottom: 5px;
    margin-right: 16px;

    .icon-link--icon-box & {
      width: 36px;
      height: 36px;
      line-height: 36px;
      margin-right: 12px;
    }
  }
}

.icon-link__text {
  text-decoration: underline;
  font-size: 16px;
  color: #1c1c1c;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: fonts.$fw-regular;

  .table-list & {
    text-decoration: none;
    font-weight: fonts.$fw-light;
  }

  .project-overview-teaser & {
    text-decoration: none;
  }

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 1.44;
  }
}
