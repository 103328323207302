@use 'scss/config';
@use 'libs/styles/helpers';

.simple-input {
  position: relative;
  display: flex;
  align-items: center;
  background: config.$color-white;
  border-radius: 2px;
  color: config.$color-cod;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid config.$color-input-border-default;
    border-radius: 2px;
    box-sizing: border-box;
    transition-property: border-color, border-width;
    transition-duration: 70ms;
    transition-timing-function: ease-in-out;
    pointer-events: none;
  }

  &:hover::before {
    border-color: config.$color-input-border-hover;
  }

  &.simple-input--error {
    &::before {
      border-color: config.$color-form-error-text;
    }
  }

  &.simple-input--focus:hover::before,
  &.simple-input--focus::before {
    border-color: config.$color-input-border-focus;
    border-width: 2px;
  }
}

.simple-input--hide-label {}

.simple-input__label {
  @include helpers.visually-hidden;
}

.simple-input__icon {
  position: absolute;
  left: 12px;
  border: 0;
  background: transparent;
  display: flex;
  align-items: flex-end;
  pointer-events: none;

  .simple-input--error & {
    color: config.$color-form-error-text;
  }
}

.simple-input__input {
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 1.45;
  color: config.$color-cod;
  outline: 0;
  border: 0;
  padding-top: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 12px;
  overflow: hidden;

  .simple-input--with-icon & {
    padding-left: 44px;
  }

  .simple-input--error & {
    color: config.$color-form-error-text;
  }

  &::-webkit-search-cancel-button {
    display: none;
    -webkit-appearance: none;
  }
}
