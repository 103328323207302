@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.assignment-report-form {
  display: flex;
  flex-direction: column;
}

.assignment-report-form__button-group {
  margin-bottom: 24px;
}

.assignment-report-form__form {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsive.target(md, xl) {
    gap: 16px;
  }
}

.assignment-report-form__project-name {
}

.assignment-report-form__second-row {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsive.target(md, xl) {
    flex-direction: row;
    gap: 16px;
  }
}

.assignment-report-form__date-range {
  display: flex;
  gap: 13px;
  align-items: flex-start;

  @include responsive.target(md, xl) {
    gap: 16px;
  }
}

.assignment-report-form__date-from,
.assignment-report-form__date-until {
  @include responsive.target(md, xl) {
    flex-direction: row;
    min-width: 181px;
  }
}

.assignment-report-form__date-divider {
  padding: 7px;

  @include responsive.target(md, xl) {
    height: 48px;
    display: flex;
    align-items: center;
  }
}

.assignment-report-form__duration {
  flex-grow: 1;
}

.assignment-report-form__hint {
  margin-top: 8px;
  color: config.$color-carbon;
}
