@use 'scss/config';

.tooltip-wrapper {
  position: absolute;
  z-index: config.$z-index-tooltip;
  top: 0;
  left: 0;
  pointer-events: all;
}

.tooltip {
  position: relative;
  filter: drop-shadow(0px 2px 10px rgba(config.$color-black, 0.2));
  max-width: 212px;
  text-align: center;
  font-size: 12px;
  line-height: 1.33;

  &.tooltip--top {
    padding-bottom: 12px;
  }

  &.tooltip--left {
    padding-right: 12px;
  }

  &.tooltip--right {
    padding-left: 12px;
  }

  &.tooltip--bottom {
    padding-top: 12px;
  }
}

.tooltip__inner {
  position: relative;
  padding-top: 16px;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 16px;
  background-color: config.$color-white;
  border-radius: 4px;
}

.tooltip__arrow {
  position: absolute;
  width: 18px;
  height: 18px;
  background-color: config.$color-white;
  border-radius: 2px;

  .tooltip--top & {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -6px) rotate(45deg);
  }

  .tooltip--left & {
    top: 50%;
    right: 0;
    transform: translate(-6px, -50%) rotate(45deg);
  }

  .tooltip--right & {
    top: 50%;
    left: 0;
    transform: translate(6px, -50%) rotate(45deg);
  }

  .tooltip--bottom & {
    top: 0;
    left: 50%;
    transform: translate(-50%, 6px) rotate(45deg);
  }
}
