@use 'scss/config';

.partner-information {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.partner-information__section {
}

.partner-information__section-title {
  margin-bottom: 24px;
}

.partner-information__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.partner-information__empty-message {
  color: config.$color-carbon;
}

.help-documents {
  display: flex;
  flex-wrap: wrap;
  column-gap: 40px;
  row-gap: 16px;
  
  .btn {
    margin: 0;
  }

  .btn + .btn {
    margin-left: 0;
  }
}
