@use 'scss/config';

.messages {
  margin-bottom: 40px;
}

.messages__message {
  width: 100%;
  display: block;
  padding: 16px;
  text-align: center;
}

.messages__message--success {
  background-color: config.$color-straw;
}

.messages__message--info {
  background-color: rgb(234, 226, 218);
}

.messages__message--error {
  background-color: config.$color-error;
  color: config.$color-white;
}
