@use 'scss/config';
@use 'libs/styles/responsive';

$track-height: 18px;
$thumb-size: 28px;

.radio-slider {
  width: 100%;
  position: relative;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding-left: $thumb-size * 0.5;
  padding-right: $thumb-size * 0.5;
  padding-bottom: 40px;
}

.radio-slider__speech-bubble {
  margin-bottom: 20px;
}

.speech-bubble {
  position: relative;
  filter: drop-shadow(0px 0px 5px rgba(config.$color-black, 0.25));
}

.speech-bubble__list {
  position: relative;
  z-index: 10;
  display: inline-block;
  background-color: config.$color-white;
  padding: 10px;
  transition: transform ease-out 0.2s;
  margin-left: -12px;
  will-change: transform;

  .radio-slider[data-only-first-and-last-bubble] & {
    transition: none;
  }
}

.speech-bubble__item {
  display: none;

  &.speech-bubble__item--active {
    display: block;
  }
}

.speech-bubble__label {
  font-size: 18px;
}

.speech-bubble__triangle {
  position: absolute;
  top: 100%;
  left: -8px;
  width: 16px;
  height: 8px;
  border-top: 8px solid white;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  transition: transform ease-out 0.2s;
  will-change: transform;
  z-index: 0;
}

.radio-slider__track {
  position: relative;
  height: $track-height;
  width: 100%;
  background-color: config.$color-straw;
  border-radius: 7px;
  cursor: pointer;

  // these sudo elements exist to increase the clickable area left and right of the track
  &::before,
  &::after {
    content: '';
    position: absolute;
    height: $thumb-size;
    width: $thumb-size * 0.5;
    top: -($thumb-size - $track-height) * 0.5;
  }

  &::before {
    right: 100%;
  }

  &::after {
    left: 100%;
  }
}

.radio-slider__click-area {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40px;
}

.radio-slider .radio-slider__thumb-transform {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  transition: transform ease-out 0.2s;
  will-change: transform;
}

.radio-slider__thumb {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: config.$color-white;
  border: 2px solid config.$color-btn-outline;
  width: $thumb-size;
  height: $thumb-size;
  cursor: pointer;
  pointer-events: none;

  .radio-slider:focus-within & {
    border-color: config.$color-black;
  }
}

.radio-slider__list {
  display: flex;
  pointer-events: none;
}

.radio-slider__item {
  position: relative;
  flex-grow: 1;
  flex-shrink: 0;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: $track-height;
    width: 2px;
    background-color: config.$color-white;
  }

  &:first-child {
    &::before {
      content: none;
    }
  }

  &:last-child {
    flex-grow: 0;
    width: 0;

    &::before {
      content: none;
    }
  }
}

.radio-slider-option {
  position: absolute;
  top: $track-height + ($thumb-size * 0.375);
  left: 0;
  font-size: 16px;
  display: block;
  transform: translateX(-50%);

  .radio-slider__item:first-child & {
    transform: none;
  }

  .radio-slider__item:last-child & {
    transform: translateX(-100%);
  }
}

.radio-slider-option__number {
}

.radio-slider-option__input {
  position: absolute;
  opacity: 0;
}
