@use 'scss/config';
@use 'libs/styles/helpers';
@use 'libs/styles/responsive';
@use 'libs/styles/fonts';

// HTML example
// <ul id="id_period-recurring" class="radio-list">
//   <li class="radio-list__item">
//     <div class="radio">
//       <div class="radio__inner" for="id_period-recurring_0">
//       <input type="radio" class="radio__input" name="period-recurring" value="once" required="" id="id_period-recurring_0">
//       <label class="radio__checkmark" for="id_period-recurring_0"></label>
//       <div class="radio__text">
//         <label class="radio__label" for="id_period-recurring_0">Unique</label>
//         <span class="radio__info">some text</span>
//       </div>
//       </div>
//     </div>
//   </li>
//   <li class="radio-list__item">
//     <div class="radio">
//     <div class="radio__inner" for="id_period-recurring_1">
//     <input type="radio" class="radio__input" name="period-recurring" value="recurring" required="" id="id_period-recurring_1">
//     <label class="radio__checkmark" for="id_period-recurring_1"></label>
//     <div class="radio__text">
//       <label class="radio__label" for="id_period-recurring_1">Recurring</label>
//       <span class="radio__info">some text</span>
//     </div>
//     </div>
//     </div>
//   </li>
// </ul>

.radio-field {
  margin-bottom: 24px;
}

.radio-field__label {
  &.text {
    display: inline-block;
    font-size: 12px;
    color: config.$color-input-label;
    line-height: 16px;
    margin-bottom: 8px;
  }
}

.radio-list {
}

.radio-list__item {
}

.radio {
  display: flex;
}

.radio__inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  padding-left: 32px;
  margin-bottom: 12px;
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  line-height: 24px;

  &.radio__inner--nowrap {
    flex-wrap: nowrap;
  }
}

.radio__input {
  @include helpers.visually-hidden;
}

.radio__label {
  position: relative;
  display: flex;
  flex-direction: column;

  &[for] {
    cursor: pointer;
  }
}

.radio__info {
  display: block;
  font-size: 14px;
  line-height: 1.375;
  font-weight: fonts.$fw-light;
  margin-top: 4px;
  margin-bottom: 12px;
  cursor: text;
}

.radio__row {
}

.radio__subfield {
  display: block;
  margin-top: 5px;
  margin-bottom: 20px;
  flex-basis: 100%;

  @include responsive.target(md, xl) {
    margin-top: 15px;
    margin-bottom: 40px;
  }
}

.radio__checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 1px solid config.$color-input-border-default;
  background-color: config.$color-white;
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 4px;
    left: 4px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: config.$color-cod;
  }

  .radio__inner:hover .radio__input ~ & {
    background-color: config.$color-stone;
  }

  .radio__inner .radio__input:checked ~ & {
    &:after {
      display: block;
    }
  }
}
