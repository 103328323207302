/*********** Colors ***********/
$color-black: #000000;
$color-white: #ffffff;
$color-blue: #3200ff;
$color-green: #c7ff00;
$color-text-grey: #bebebe;
$color-text-footer-contrast: #4f4f4f;
$primary-color: #dbaa35;
$primary-color-light: #e8c880;
$color-silver: #cccccc;
$color-mercury: #e6e6e6;
$color-alabaster: #fafafa;
$color-nav-meta-bg: $color-alabaster;
$color-cod: #1c1c1c;
$color-chocolate: rgb(88, 81, 72); // Chocolate
$color-clay: rgb(128, 114, 102); // Clay
$color-mouse: rgb(187, 179, 172); // Mouse
$color-sand: rgb(184, 157, 131); // Sand
$color-sand-background: #eae2da; // Sand
$color-caramel: rgb(215, 194, 170); // Caramel
$color-ginger: rgb(235, 222, 205); // Ginger
$color-carbon: rgb(100, 100, 100); // Carbon
$color-smoke: rgb(145, 145, 145); // Smoke
$color-stone: rgb(190, 190, 190); // Stone
$color-chestnut: rgb(154, 61, 55); // Chestnut
$color-terracotta: rgb(163, 102, 80); // Terracotta
$color-cinnamon: rgb(212, 173, 156); // Cinnamon
$color-curry: rgb(219, 170, 53); // Curry
$color-honey: rgb(232, 200, 128); // Honey
$color-straw: rgb(241, 228, 186); // Straw
$color-olive: #6a7d39;
$color-fern: #b4b77b;
$color-pine: #3b8085;
$color-text-2: $color-carbon;
$color-text-black: $color-cod;
$color-background: #f3f3f3;
$color-error-light: #cc9e9b;
$color-error: #9a3d37;
$color-form-error-bg: #f7e1df;
$color-form-error-text: #9a3d37;
$color-input-border-default: #aaaaaa;
$color-input-border-hover: #444444;
$color-input-border-focus: #009bd2;
$color-input-label: #646464;
$color-btn-outline: #646464;
$color-flyout: #dee2e9;
$color-light-grey-background: #eeeeee;

/*********** Breakpoints ***********/
$breakpoints: (
  // Breakpoint Definition
  xs: 0,
  // Extra small screen / phone
  sm: 576px,
  // Small screen / phone
  md: 768px,
  // Medium screen / tablet
  lg: 992px,
  // Large screen / desktop
  xl: 1280px // Extra large screen / wide desktop
);

$grid-xs: (
  columns: 3,
  gutter-width: 20px,
  container-max-width: 100%,
);
$grid-sm: (
  columns: 3,
  gutter-width: 20px,
  container-max-width: 100%,
);
$grid-md: (
  columns: 6,
  gutter-width: 30px,
  container-max-width: 678px,
);
$grid-lg: (
  columns: 12,
  gutter-width: 32px,
  container-max-width: 904px,
);
$grid-xl: (
  columns: 12,
  gutter-width: 40px,
  container-max-width: 1320px,
);
$grid: (
  xs: $grid-xs,
  sm: $grid-sm,
  md: $grid-md,
  lg: $grid-lg,
  xl: $grid-xl,
);

/*************** Fonts ***************/
$font-Frutiger: 'Frutiger';
$font-Icons: 'Icons';

/*************** Dimensions ***************/
$header-height--mobile: 80px;
$header-height--desktop: 100px;

/*************** Layers ***************/
$z-index-auto-complete: 750;
$z-index-profile-navigation: 775;
$z-index-profile-booking-banner: 785;
$z-index-search-toggler: 800;
$z-index-language-switch: 800;
$z-index-main-nav: 850;
$z-index-header: 900;
$z-index-select-field: 1000;
$z-index-overlay: 2000;
$z-index-modal-outlet: 2500;
$z-index-skip-links: 3000;
$z-index-tooltip: 4000;
$z-index-privacy-banner: 4500;
$z-index-focus-layer: 5000;
