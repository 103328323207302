@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.category-choice-field {
  margin-bottom: 40px;

  @include responsive.target(md, xl) {
    margin-bottom: 56px;
  }
}

.category-choice {
}

.category-choice__visual {
  display: flex;
  background-color: config.$color-straw;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: fonts.$fw-medium;
  color: config.$color-text-black;

  .category-choice__input:checked + & {
    background-color: config.$color-curry;
    color: config.$color-white;
  }
}

.category-choice__label {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 12px;
  cursor: pointer;
  user-select: none;

  @include responsive.target(md, xl) {
    padding-left: 20px;
  }
}

.category-choice__label__icon {
  font-family: config.$font-Icons;
  font-size: 24px;
  margin-left: 5px;
  display: inline-block;
  opacity: 0;

  .category-choice__input:checked + .category-choice__visual & {
    opacity: 1;
  }

  @include responsive.target(md, xl) {
    margin-left: 10px;
  }
}

.category-choice__input {
  @include helpers.visually-hidden;
}

.category-choice__info-toggle {
  position: relative;
  font-weight: fonts.$fw-light;
  font-size: 14px;
  min-width: 71px;
  margin-right: 12px;
  cursor: pointer;
  text-align: right;
  user-select: none;
  border: 0;
  background-color: transparent;
  font-family: inherit;

  &:before {
    position: absolute;
    content: '';
    left: -4px;
    width: 1px;
    top: -8px;
    bottom: -8px;
    background: config.$color-cod;
    opacity: 0.5;
  }

  .category-choice__input:checked + .category-choice__visual &:before {
    background: config.$color-white;
  }

  .category-choice__input:checked + .category-choice__visual & {
    color: config.$color-white;
  }

  .navigating-with-keyboard & {
    display: none;
  }

  @include responsive.target(md, xl) {
    font-size: 16px;
    min-width: 79px;
    margin-right: 20px;

    &:before {
      left: -14px;
    }
  }
}

.category-choice__info {
  display: none;
  padding-left: 8px;

  .category-choice--info-open & {
    display: block;
  }

  .navigating-with-keyboard & {
    display: none;
  }

  .navigating-with-keyboard .category-choice--focus & {
    display: block;
    padding-top: 10px;
  }
}
