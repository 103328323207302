@use 'scss/config';
@use 'libs/styles/responsive';
@use 'libs/styles/fonts';

// HTML example:
// <table class="factsheet-table">
//   <tr>
//     <th class="factsheet-table__title">Gesamtkosten</th>
//     <td class="factsheet-table__content">1000.00 CHF</td>
//   </tr>
//   <tr>
//     <th class="factsheet-table__title">Plätze</th>
//     <td class="factsheet-table__content">15 Teilnehmer</td>
//   </tr>
//   <tr>
//     <th class="factsheet-table__title">Ort</th>
//     <td class="factsheet-table__content">Zürich oder Remote</td>
//   </tr>
//   <tr>
//     <th class="factsheet-table__title">Datum</th>
//     <td class="factsheet-table__content">18.06.2022</td>
//   </tr>
//   <tr>
//     <th class="factsheet-table__title">Uhrzeit</th>
//     <td class="factsheet-table__content">13:00 - 18:00 Uhr</td>
//   </tr>
//   <tr>
//     <th class="factsheet-table__title">Umsetzungspartner</th>
//     <td class="factsheet-table__content">Partner GmbH</td>
//   </tr>
//   <tr>
//     <th class="factsheet-table__title">Factsheet</th>
//     <td class="factsheet-table__content factsheet-table__content--highlighted">Download</td>
//   </tr>
// </table>

.factsheet-table {
  border-style: hidden;
  &.factsheet-table--full-width {
    width: 100%;
  }

  &.factsheet-table--cv-offer {
    margin-bottom: 30px;

    @include responsive.target(md, xl) {
      margin-bottom: 40px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.factsheet-table--no-margin {
    margin-bottom: 0;

    @include responsive.target(md, xl) {
      margin-bottom: 0;
    }
  }
}

.factsheet-table__title {
  font-size: 16px;
  font-weight: fonts.$fw-medium;
  line-height: 24px;
  padding: 7px 5px 7px 16px;
  background-color: config.$color-light-grey-background;
  border: 4px solid config.$color-white;
  text-align: start;
  padding: 7px 8px 7px 16px;

  &.factsheet-table__title--kmu {
    background-color: config.$primary-color-light;
  }

  .factsheet-table--cv-offer & {
    width: 25%;
    min-width: 140px;
  }

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
    padding: 7px 25px 7px 10px;
  }

  .factsheet-table--dense & {
    font-size: 10px;
    line-height: 18px;
    padding: 7px 25px 7px 16px;

    @include responsive.target(md, xl) {
      font-size: 16px;
      line-height: 23px;
      padding: 7px 25px 7px 16px;
    }
  }
}

.factsheet-table__content {
  font-size: 16px;
  font-weight: fonts.$fw-light;
  line-height: 24px;
  padding: 7px 5px 7px 16px;
  background-color: config.$color-light-grey-background;
  border: 4px solid config.$color-white;
  width: 100%;
  padding: 7px 16px 7px 5px;
  vertical-align: middle;
  &.factsheet-table__content--kmu {
    background-color: config.$primary-color-light;
  }

  .factsheet-table--cv-offer & {
    width: auto;
  }

  .factsheet-table--horizontal & {
    vertical-align: top;
  }

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
    padding: 7px 16px 7px 8px;
  }
  strong {
    font-weight: fonts.$fw-medium;
  }

  &.factsheet-table__content--impl-proposal {
    width: 33.33%;
  }

  .factsheet-table--dense & {
    font-size: 10px;
    line-height: 18px;
    padding: 7px 25px 7px 16px;

    @include responsive.target(md, xl) {
      font-size: 16px;
      line-height: 23px;
      padding: 7px 25px 7px 16px;
    }
  }
}

.factsheet-table__link {
  font-weight: fonts.$fw-medium;
  width: 100%;
  text-decoration: none;

  color: config.$color-curry;
}
