@use '../../../scss/config';
@use '../../../libs/styles/helpers';
@use '../../../libs/styles/responsive';

.select-field {
  display: inline-block;
  background: config.$color-white;
  width: 100%;
  position: relative;

  &.select-field--open {
    z-index: config.$z-index-select-field;
  }
}

.select-field__inner {
  .select-field--open & {
    position: relative;
    z-index: 10;
  }
}

.select-field__button {
  position: relative;
  display: block;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid config.$color-input-border-default;
    border-radius: 2px;
    box-sizing: border-box;
    transition-property: border-color, border-width;
    transition-duration: 70ms;
    transition-timing-function: ease-in-out;
    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    right: 11px;
    bottom: 13px;
    width: 0;
    height: 0;
    transform: rotate(0deg);
    transform-origin: 50%;
    transition-property: transform;
    transition-duration: 70ms;
    transition-timing-function: ease-in-out;
    border-style: solid;
    border-width: 6px 5px 0 5px;
    border-color: config.$color-input-border-hover transparent transparent
      transparent;
  }

  .form-field:hover & {
    &::before {
      border-color: config.$color-input-border-hover;
    }
  }

  .form-field--error & {
    &::before {
      border-color: config.$color-form-error-text;
    }
  }

  .select-field--open & {
    z-index: 1;

    &::before {
      content: none;
    }

    &::after {
      transform: rotate(-180deg);
    }
  }
}

.select-field__label {
  font-size: 16px;
  color: config.$color-input-label;
  line-height: 22px;
  position: absolute;
  left: 12px;
  bottom: 6px;
  pointer-events: none;
  user-select: none;
  transform-origin: 0 100%;
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;

  .select-field--filled & {
    transform: translateY(-22px) scale(0.75);
  }
}

.select-field__selection {
  display: block;
  position: relative;
  width: 100%;
  font-size: 16px;
  color: config.$color-cod;
  line-height: 22px;
  outline: 0;
  border: 0;
  padding: 20px 12px 6px;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  visibility: hidden;

  .select-field--filled & {
    visibility: visible;
  }
}

.select-field__values {
  visibility: hidden;

  .select-field--filled & {
    visibility: visible;
  }
}

.select-field__dropdown {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  margin: -8px;
  padding: 64px 8px 8px;
  width: calc(100% + 16px);
  background: config.$color-white;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.22), 0 3px 8px 0 rgba(0, 0, 0, 0.12);

  .select-field--open & {
    display: block;
  }
}

.select-field__dropdown-inner {
  display: block;
  overflow: auto;
  max-height: 264px;
}

.select-option {
  position: relative;
  display: flex;
  width: 100%;

  &.select-option--checkbox {
    padding-left: 12px;
    padding-right: 12px;
  }

  &.select-option--radio {
    &::before {
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background: config.$color-mercury;
      opacity: 0;
    }

    &.select-option--checked,
    &.select-option--has-focus,
    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
}

.select-option__inner {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
}

.select-option__input {
  @include helpers.visually-hidden;
}

.select-option__checkmark {
  position: relative;
  height: 24px;
  width: 24px;
  border: 1px solid config.$color-input-border-default;
  background-color: config.$color-white;
  border-radius: 2%;
  margin-right: 8px;
  flex-shrink: 0;
  cursor: pointer;

  &:after {
    left: 9px;
    top: 5px;
    width: 3px;
    height: 7px;
    border: solid config.$color-white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: '';
    position: absolute;
    display: none;
  }

  .select-option__inner:hover .select-option__input ~ & {
    background-color: config.$color-stone;
  }

  .select-option__inner .select-option__input:checked ~ & {
    background-color: config.$color-input-border-hover;
    border-color: config.$color-input-border-hover;
  }

  .select-option__inner .select-option__input:checked ~ &:after {
    display: block;
  }
}

.select-option__label {
  position: relative;
  cursor: pointer;

  .select-option--radio & {
    width: 100%;
    padding: 10px 12px;
  }

  .select-option--checkbox & {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.select-field__backdrop {
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
  display: none;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  .select-field--open & {
    display: block;
    pointer-events: initial;
  }
}
