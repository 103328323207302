@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.company-edit {
}

.company-edit__section {
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    margin-bottom: 64px;
  }
}

.company-message-form {
  margin-bottom: 16px;
}

.company-edit__admin-list {
  margin-bottom: 16px;

  @include responsive.target(md, xl) {
    margin-bottom: 16px;
  }
}

.admin-list {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include responsive.target(md, xl) {
    gap: 40px;
  }
}

.admin-list__item {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsive.target(md, xl) {
    gap: 16px;
  }
}

.admin-list__row {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsive.target(md, xl) {
    flex-direction: row;
    gap: 16px;
  }
}

.admin-list__column {
  @include responsive.target(md, xl) {
    flex-grow: 1;

    &.admin-list__column--phone {
      flex-shrink: 0;
      flex-grow: 0;
      flex-basis: 273px;
    }
  }
}

.company-edit__contact-forms {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 32px;

  @include responsive.target(md, xl) {
    gap: 40px;
    margin-bottom: 40px;
  }
}

.company-contact-form {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsive.target(md, xl) {
    gap: 16px;
  }
}

.company-contact-form__row {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsive.target(md, xl) {
    flex-direction: row;
    gap: 16px;
  }
}

.company-contact-form__column {
  flex-grow: 1;

  @include responsive.target(md, xl) {
    &.company-contact-form__column--phone {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 273px;
    }
  }
}

.billing-form {
  margin-bottom: 16px;
}

.billing-form__post-code {
  @include responsive.target(md, xl) {
    flex-grow: 0;
    flex-basis: 273px;
  }
}

.company-details-form {
  margin-bottom: 16px;
}

.company-edit__meta-data {
  margin-top: 32px;
}

.company-edit-meta {
}

.company-edit-meta__title {
  margin-bottom: 24px;
}

.company-edit-meta__form {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  gap: 8px;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    gap: 16px;
  }
}

.company-edit-meta__row {
}

.company-agb-link {
  display: block;
  margin-top: 24px;
  color: config.$color-cod;
  font-weight: fonts.$fw-light;
}
