@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.company-contacts {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.company-contacts__item {
}

.company-contact {
  font-size: 16px;
  line-height: 1.5;
}

.company-contact__name {
  font-weight: fonts.$fw-medium;
  margin-bottom: 0;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 1.44;
  }
}

.company-contact__value {
}
