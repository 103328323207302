@use 'scss/config';

.choice-field {
  margin-bottom: 24px;
}

.choice-field__label {
  &.text {
    display: inline-block;
    font-size: 12px;
    color: config.$color-input-label;
    line-height: 16px;
    margin-bottom: 8px;
  }
}
