@use 'scss/config';
@use 'libs/styles/responsive';

.profile-invitation-page {
}

.profile-invitation-page__title {
  margin-bottom: 16px;

  @include responsive.target(md, xl) {
    margin-bottom: 24px;
  }
}

.profile-invitation-page__text {
  margin-bottom: 16px;
}
