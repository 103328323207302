@use 'scss/config';
@use 'libs/styles/responsive';

.attendees {
  width: 100%;
  margin-bottom: 50px;
  border-collapse: separate;
  border-spacing: 0 10px;

  th {
    text-align: left;
    padding-bottom: 20px;
  }

  td {
    width: 100%;
  }

  tr {
    background-color: transparent;
    transition: background-color 0.2s ease;

    &:hover {
      background-color: config.$color-light-grey-background;
    }
  }

  .btn:not(:hover) {
    background-color: config.$color-white;
  }
}

.attendees-title {
  margin-bottom: 0;
}

.attendee-action {
  text-align: right;
}

.attendee-action__form {
  display: flex;
  flex-direction: row;

  .btn+.btn {
    margin-left: 10px;
  }

  @include responsive.target(xs, sm) {
    flex-direction: column;

    .btn+.btn {
      margin-left: 0;
      margin-top: 5px;
    }
  }
}
