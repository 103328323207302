@use 'libs/styles/responsive';

.text-with-image {
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
}

.text-with-image__image {
  @include responsive.target(xs, sm) {
    display: none;
  }

  margin-right: 60px;
  flex: 0 1 320px;

  img {
    width: 100%;
  }
  .text-with-image--small & {
    flex: 0 1 116px;
  }
}

.text-with-image__text {
  flex: 0 1 703px;
  .text-with-image--small & {
    flex: 0 1 907px;
  }
}
