@use 'scss/config';
@use 'libs/styles/responsive';

.list-filters-toolbar {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsive.target(md, xl) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.list-filters-toolbar__item {
}

.list-filters-toolbar__search-spacer {
  flex-grow: 1;

  @include responsive.target(md, xl) {
    display: none;
  }
}

.list-filters-toolbar__buttons {
  display: flex;
  align-items: stretch;
  gap: 8px;
}

.list-filters-toolbar__button-item {
}

.list-filters-toolbar__button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 6px;
  padding-bottom: 6px;
  min-width: 58px;

  &.list-filters-toolbar__button--search {
    border-color: config.$color-input-border-default;
  }

  @include responsive.target(md, xl) {
    padding-left: 24px;
    padding-right: 24px;

    &.list-filters-toolbar__button--search {
      display: none;
    }
  }
}

.filter-button {
  @include responsive.target(xs, sm) {
    padding-left: 13px;
    padding-right: 13px;
  }
}

.filter-button__icon {
  font-size: 28px;
}

.filter-button__text {
  display: none;

  @include responsive.target(md, xl) {
    display: block;
  }
}

.list-filters-toolbar__search-field {
  width: 100%;

  @include responsive.target(md, xl) {
    width: auto;

    &[data-expandable] {
      display: block;
      height: auto;
      opacity: 1;
    }
  }
}

.list-filters-toolbar__search-field-inner {
}
