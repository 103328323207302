.group-members {
  width: 100%;
  margin-bottom: 50px;

  th {
    text-align: left;
    padding-bottom: 20px;
  }

  td {
    padding-bottom: 10px;
  }
}
