@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.next-steps {
  position: relative;
  counter-reset: next-steps;
}

.next-steps__list {
}

.next-steps__line {
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  bottom: 0;
  width: 1px;
  background-color: config.$color-curry;

  @include responsive.target(md, xl) {
    width: 2px;
    left: 27px;
  }
}

.next-steps__step {
  position: relative;
  font-weight: fonts.$fw-light;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 1.5;
  counter-increment: next-steps;

  &::before {
    content: counter(next-steps);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: config.$color-curry;
    border-radius: 50%;
    color: config.$color-white;
    margin-right: 16px;
    font-size: 18px;
    font-weight: fonts.$fw-medium;
    flex-shrink: 0;
    align-self: flex-start;
    line-height: 1;
  }

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 1.44;

    &::before {
      width: 56px;
      height: 56px;
      font-size: 28px;
    }
  }
}
