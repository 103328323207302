@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.toggle-field {
  margin-bottom: 20px;
}

.toggle-field__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toggle-field__label {
  margin: 0 15px;
  cursor: pointer;

  .toggle-field--bold & {
    font-weight: fonts.$fw-bold;
  }

  &--disabled {
    color: config.$color-input-border-default;
  }
}

.toggle-field__input {
  width: 100%;
  height: 100%;
  margin: 0;
  opacity: 0;
}

.switch {
  cursor: pointer;
  display: block;
  position: relative;
  width: 68px;
  height: 24px;
  border-radius: 24px;
  overflow: hidden;
}

.switch__value {
  position: absolute;
  display: flex;
  flex-direction: row;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 12px;
  font-weight: fonts.$fw-bold;
  text-transform: uppercase;
  color: config.$color-white;
  align-items: center;
  transition: opacity 300ms ease-in-out;
  user-select: none;

  &--on {
    opacity: 0;
    justify-content: flex-start;
    background-color: config.$color-olive;
  }

  &--off {
    opacity: 1;
    justify-content: flex-end;
    background-color: config.$color-chestnut;
  }

  .toggle-field__input:checked ~ & {
    &--on {
      opacity: 1;
    }

    &--off {
      opacity: 0;
    }
  }

  .toggle-field__input[disabled] ~ & {
    &--on {
      opacity: 0;
    }

    &--off {
      background-color: config.$color-input-border-default;
    }
  }
}

.switch__toggler {
  position: absolute;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: config.$color-white;
  border: 1px solid config.$color-input-border-default;
  transition: transform 300ms ease-in-out;

  .toggle-field__input:checked ~ & {
    transform: translateX(44px);
  }
}

.switch__value-text {
  width: 44px;
  text-align: center;
}
