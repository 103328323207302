@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.recurring-project-signup-modal {
  position: relative;
  border: 0;
  background-color: config.$color-white;
  display: flex;
  margin: 16px;
  max-width: 867px;
  max-height: 90vh;
  padding: 0;

  @include responsive.target(md, xl) {
    margin: 32px;
  }
}

.recurring-project-signup-modal__close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.recurring-project-signup-modal-close-button {
  display: block;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  font-size: 20px;
}

.recurring-project-signup-modal-close-button__icon {
  font-family: config.$font-Icons;
}

.recurring-project-signup-modal__main {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-top: 40px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 32px;

  @include responsive.target(md, xl) {
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
  }
}

.recurring-project-signup-modal__heading {
  font-size: 28px;
  line-height: 1.2;
  font-weight: fonts.$fw-light;
  margin-bottom: 16px;

  @include responsive.target(md, xl) {
    margin-bottom: 24px;
  }
}

.recurring-project-signup-modal__button {
  margin-bottom: 16px;

  @include responsive.target(md, xl) {
    margin-bottom: 24px;
  }
}
