@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.pv-teaser {
  margin-bottom: 80px;
}

.pv-teaser-content {
  display: flex;
  flex-direction: column;
  position: relative;
  // min-height: 688px;
  margin-right: 20px;
  padding: 20px 12px;
  background-color: config.$color-straw;
  --data-shaddow: 0px;

  .pv-teaser--waiting-list & {
    background-color: config.$color-mercury;

    &:before {
      background-color: config.$color-silver;
    }
  }

  .pv-teaser--own-project & {
    @include responsive.target(md) {
      flex-direction: column;
    }
  }

  @include responsive.target(md) {
    flex-direction: row;
    max-width: 1100px;
    padding: 20px 44px;
    min-height: 475px;
  }

  @include responsive.target(lg, xl) {
    flex-direction: row;
    max-width: 1100px;
    padding-top: 20px;
    padding-right: 43px;
    min-height: 475px;
  }

  &:before {
    content: '';
    position: absolute;
    right: -20px;
    bottom: -20px;
    height: calc(100% - var(--data-shaddow));
    width: 50%;
    background-color: config.$color-honey;
    z-index: -1;

    @include responsive.target(md, xl) {
      width: 42%;
    }
  }
}

.pv-teaser-content__warning {
  font-size: 14px;
  color: config.$color-form-error-text;
  line-height: 16px;
  background: config.$color-form-error-bg;
  padding: 5px 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 14px;
  margin-bottom: 20px;

  span + .btn {
    margin-top: 1.2em;
  }
}

.pv-teaser-illustration-box {
  display: none;

  @include responsive.target(lg, xl) {
    display: block;
    width: 325px;
    flex: 0 1 auto;
  }

  .pv-teaser--own-project & {
    display: block;
    width: 100%;
    min-height: 300px;
    order: 2;

    @include responsive.target(md, xl) {
      order: 1;
    }
  }
}

.pv-teaser-image-box {
  display: block;
  width: 100%;
  height: 100%;

  .pv-teaser--waiting-list & {
    background: config.$color-smoke;
  }

  .image-box__image {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
  }
}

.pv-teaser-graphic-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;

  @include responsive.target(md) {
    padding-top: 50px;
    border-top: 2px solid config.$color-smoke;
  }

  @include responsive.target(lg, xl) {
    padding-top: 20px;
  }

  .project-progress {
    transform: none;
    scale: 1;
    margin-bottom: 20px;
    color: config.$color-black;
  }

  .info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include responsive.target(md, xl) {
      flex-direction: row;
    }
  }

  .volunteers {
    width: 50%;
  }

  .days_until {
    width: 50%;
  }

  .volunteers__countdown,
  .days_until__countdown {
    font-weight: fonts.$fw-medium;
    font-size: 24px;
    color: config.$color-cod;
    text-align: center;
    line-height: 30px;

    @include responsive.target(md, xl) {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 32px;
    }
  }

  .volunteers__text,
  .days_until__text {
    font-size: 16px;
    color: config.$color-cod;
    text-align: center;
    line-height: 22px;
  }
}

.pv-teaser-text-box {
  width: 100%;

  .pv-teaser--own-project {
    order: 1;
  }

  @include responsive.target(lg, xl) {
    order: 2;
    margin-left: 44px;
    width: 622px;
    flex: 0 0 auto;
  }
}

.pv-teaser-info {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include responsive.target(md, xl) {
    flex-direction: row;
    align-items: flex-start;
  }
}

.pv-teaser-project-name {
  width: 100%;
  font-weight: fonts.$fw-medium;
  font-size: 24px;
  color: config.$color-cod;
  text-align: center;
  line-height: 30px;
  margin-bottom: 10px;

  @include responsive.target(md, xl) {
    text-align: left;
    margin-bottom: 40px;
    font-size: 28px;
    line-height: 34px;
  }
}

.pv-teaser-time {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid config.$color-smoke;
  padding-bottom: 50px;

  @include responsive.target(md, xl) {
    width: 50%;
    align-items: flex-start;
    border-bottom: none;
  }
}

.pv-teaser-location {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px 0;
  border-bottom: 2px solid config.$color-smoke;

  @include responsive.target(md, xl) {
    width: 50%;
    align-items: flex-start;
    border-bottom: none;
    padding: 0;
  }
}

.pv-teaser__text {
  font-size: 16px;
  color: config.$color-cod;
  line-height: 22px;
  max-width: 100%;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
  }
}

.pv-teaser-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .text,
  a {
    max-width: 100%;
  }

  @include responsive.target(md, xl) {
    border-top: 2px solid config.$color-smoke;
    padding-top: 43px;
    align-items: flex-start;

    .pv-teaser--partner & {
      flex-direction: row;
    }
  }

  .social-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 2px solid config.$color-smoke;
    border-bottom: 2px solid config.$color-smoke;
    padding-top: 50px;
    width: 100%;

    @include responsive.target(md, xl) {
      border: none;
      padding-top: 0;
      align-items: flex-start;
      width: 50%;
    }

    .pv-teaser__text {
      text-align: center;
      margin-bottom: 20px;

      @include responsive.target(md, xl) {
        text-align: left;
      }
    }
  }

  .social-media__links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 39px;
  }

  .social-media__link {
    margin-right: 10px;
    display: inline-block;
  }

  .icon {
    font-size: 16px;
    color: config.$color-white;
    margin: 0;
  }
}

.time__link,
.location__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 23px;

  @include responsive.target(md, xl) {
    flex-direction: row;
    margin-top: 10px;
  }
}

.time__text,
.location__text,
.organiser__text,
.cancellation__text,
.participants__text {
  font-size: 16px;
  color: config.$color-cod;
  line-height: 22px;

  // margin-bottom: 10px;
  @include responsive.target(xs, sm) {
    text-align: center;
  }

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
  }
}

.link__icon {
  margin-bottom: 5px;
}

.pv-teaser-links__regular {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  align-items: stretch;

  @include responsive.target(md, xl) {
    align-items: flex-start;
    width: 50%;
  }
}

.pv-teaser-links__group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 50px 0;
  border-top: 2px solid config.$color-smoke;

  @include responsive.target(md, xl) {
    align-items: flex-start;
  }
}

.pv-teaser-link__project {
  width: 100%;
  height: 32px;
  text-decoration: none;
  color: config.$color-cod;
  margin: 0 auto 10px;

  @include responsive.target(md, xl) {
    margin: 0 0 10px 0;
    width: 280px;
  }
}

.pv-teaser-link__organiser {
  margin-bottom: 10px;
}

.pv-teaser__text {
  font-size: 16px;
  color: config.$color-cod;
  text-align: center;
  line-height: 22px;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
    text-align: left;
  }

  .pv-teaser-links__group & {
    margin-bottom: 10px;
  }
}

.pv-teaser__text__group {
  font-size: 18px;
  font-weight: fonts.$fw-bold;
  line-height: 26px;
  margin-bottom: 10px;
}

.pv-teaser__text__name {
  font-size: 16px;
  margin-bottom: 16px;
}
