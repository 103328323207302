.page-layout {
  display: flex;
  min-height: inherit;
  flex-direction: column;
}

.page-layout__skip-links {
}

.page-layout__header {
}

.page-layout__main {
  flex-grow: 1;
}

.page-layout__footer {
}
