[data-expandable-toggle] {
}

[data-expandable] {
  overflow: hidden;
  display: none;
  height: 0;
  opacity: 0;

  &.is-expanded {
    display: block;
    height: auto;
    opacity: 1;
  }
}

[data-expandable-content] {
}
