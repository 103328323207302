@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.skip-links {
}

.skip-link {
  position: fixed;
  display: block;
  top: -1000px;
  z-index: config.$z-index-skip-links;
  opacity: 0;
  transition: opacity ease 0.3s;
  color: config.$color-cod;
  font-weight: fonts.$fw-regular;
  text-decoration: none;
  padding: 20px;
  background-color: config.$color-white;
  box-shadow: 0 0 20px rgba(config.$color-black, 0.2);

  &:focus {
    top: config.$header-height--mobile;
    opacity: 1;
  }

  @include responsive.target(md, xl) {
    &:focus {
      top: config.$header-height--desktop;
      left: 20px;
      margin-top: 20px;
    }
  }
}
