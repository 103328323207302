@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.box-cta-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  @include responsive.target(lg, xl) {
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
}

.box-cta {
  border-radius: 8px;
  background-color: config.$color-straw;
  padding-top: 14px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 14px;

  @include responsive.target(md, xl) {
    padding-top: 40px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 44px;
  }
}
.box-cta--with-number {
  display: flex;
  align-items: center;
}

.box-cta__number {
  padding-right: 24px;
  font-size: 32px;
  font-weight: fonts.$fw-light;
  text-align: right;
  min-width: 66px;
  flex-shrink: 0;
  color: config.$color-text-black;

  @include responsive.target(md, xl) {
    min-width: 115px;
    padding-right: 40px;
    font-size: 64px;
  }
}

.box-cta__main {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.box-cta__text {
  font-size: 16px;
  line-height: 1.5;
  font-weight: fonts.$fw-light;
  color: config.$color-text-black;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 1.44;
  }
}

.box-cta__action {
}
