@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.search {
  display: none;
  width: 100%;
  z-index: 2;
  position: relative;
  height: 100px;

  @include responsive.target(lg, xl) {
    height: 128px;
  }

  &--active {
    display: block;
  }
}

.search__inner {
  position: fixed;
  top: #{config.$header-height--mobile};
  left: 0;
  right: 0;
  background-color: config.$color-nav-meta-bg;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include responsive.target(xs, md) {
    @include helpers.headerTransition;
    padding: 0 16px;

    .header--small ~ .search & {
      transform: translateY(-30px);
    }
  }

  @media (max-width: 350px) {
    transform: translateY(15px);

    .header--small ~ .search & {
      transform: translateY(-17px);
    }
  }

  @include responsive.target(lg, xl) {
    top: #{config.$header-height--desktop};
    height: 128px;
  }
}

.search__field {
  max-width: 500px;
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid config.$color-cod;

  @include responsive.target(lg, xl) {
    margin-bottom: 24px;
  }

  .icon-close {
    font-size: 20px;
    margin-left: 16px;

    @include responsive.target(lg, xl) {
      font-size: 24px;
      margin-left: 20px;
    }
  }

  .icon-search {
    font-size: 28px;
    margin-left: 16px;

    // margin-bottom: 10px;
    @include responsive.target(lg, xl) {
      font-size: 36px;
      margin-left: 20px;
      // margin-bottom: 10px;
    }
  }

  button {
    background-color: transparent;
    border: none;
  }
}

.search__text {
  display: block;
  // max-width: 410px;
  background-color: transparent;
  font-weight: fonts.$fw-light;
  font-size: 32px;
  letter-spacing: 0;
  text-align: left;
  line-height: 28px;
  margin-bottom: 8px;
  color: config.$color-black;
  flex: 1 1 auto;
  border: none;
  width: 100%;

  @include responsive.target(lg, xl) {
    font-size: 40px;
    line-height: 32px;
    margin-bottom: 11px;
  }
}

.search-result__text {
  .highlighted {
    font-weight: fonts.$fw-bold;
  }
}

.search-result {
  margin-bottom: 50px;
}

.search-result__link {
  display: block;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 10px;
}

.search-result__date {
  color: config.$color-silver;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 10px;
}

.search-separator {
  margin-bottom: 80px;
}

.search__reset {
  text-decoration: none;
}

.search__paginator {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search__paginator_pages {
  padding: 0 16px;
}
