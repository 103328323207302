@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.message-box {
  background-color: config.$color-background;
  border-radius: 4px;
}
.message-box--invitation {
  background-color: config.$color-sand-background;
}

.message-box__inner {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;

  @include responsive.target(md, xl) {
    padding-top: 24px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 24px;
    gap: 24px;
  }
}

.message-box__icon {
  align-self: flex-start;
  font-family: config.$font-Icons;
  color: config.$color-white;
  background-color: config.$color-curry;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;

  .message-box--invitation & {
    background-color: config.$color-sand;
  }

  @include responsive.target(lg, xl) {
    margin-top: 0;
  }
}

.message-box__icon--info {
  font-size: 30px;
}

.message-box__content {
}

.message-box__title {
  font-size: 16px;
  font-weight: fonts.$fw-medium;
  line-height: 1.5;

  @include responsive.target(lg, xl) {
    font-size: 18px;
    line-height: 1.44;
    margin-bottom: 8px;
  }
}

.message-box__text {
  font-size: 16px;
  line-height: 1.5;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 1.38;
  }
}

.message-box__actions {
  display: flex;
  margin-top: 32px;
  gap: 10px;
}
