$fw-thin: 100;
$fw-extra-light: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 550;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;
$fw-extra-heavy: 900;
$fs-normal: 'normal';
$fs-italic: 'italic';
$ft-woff2: 'woff2';
$ft-woff: 'woff';
$ft-ttf: 'ttf';
$ft-svg: 'svg';
$ft-eot: 'eot';
$FontCfg: ();

@mixin font-face-src($FontPath, $FontVersion, $FontFormats) {
  $src_list: '';

  @for $i from 1 through length($FontFormats) {
    $format: nth($FontFormats, $i);

    @if $i !=1 {
      $src_list: '#{$src_list},';
    }

    $s: '';

    @if $format==$ft-eot {
      $s: "url('#{$FontPath}.#{$format}?v=#{$FontVersion}'), url('#{$FontPath}.#{$format}?v=#{$FontVersion}#iefix') format('embedded-opentype')";
    } @else {
      $s: 'url("#{$FontPath}.#{$format}?v=#{$FontVersion}") format(\'#{$format}\')';
    }

    $src_list: '#{$src_list}#{$s}';
  }

  src: #{$src_list};
}

@mixin generate-font(
  $FontFamily,
  $FontPath,
  $FontStyles: ('': ()),
  $FontFormats: ($ft-woff2, $ft-woff),
  $FontVersion: '1.0',
  $FontLigatures: False,
  $FontAntialiased: True
) {
  $new: (
    $FontFamily: (
      ligatures: $FontLigatures,
      antialiased: $FontAntialiased,
    ),
  );
  $FontCfg: map-merge($FontCfg, $new) !global;

  @if $FontStyles != '' {
    @each $prefix, $cfg in $FontStyles {
      $cfg: map-merge(
        (
          weight: $fw-regular,
          style: $fs-normal,
        ),
        $cfg
      );

      @font-face {
        font-family: #{$FontFamily};
        @include font-face-src(
          #{$FontPath + $prefix},
          $FontVersion,
          $FontFormats
        );
        font-weight: map-get($cfg, $key: weight);
        font-style: map-get($cfg, $key: style);
      }
    }
  } @else {
    @font-face {
      font-family: #{$FontFamily};
      @include font-face-src($FontPath, $FontVersion, $FontFormats);
      font-weight: #{$fw-regular};
      font-style: #{$fs-normal};
    }
  }
}

@mixin font($FontFamily, $FontWeight: $fw-regular, $FontStyle: $fs-normal) {
  $cfg: map-get(
    $map: $FontCfg,
    $key: $FontFamily,
  );
  font-family: #{$FontFamily};
  font-weight: #{$FontWeight};
  font-style: #{$FontStyle};

  @if map-get($map: $cfg, $key: ligatures) == True {
    font-variant-ligatures: discretionary-ligatures;
    font-feature-settings: 'liga' 1;
  }

  @if map-get($map: $cfg, $key: antialiased) == True {
    @include nice-fonts;
  }
}

@mixin nice-fonts {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
