@use 'scss/config';
@use 'libs/styles/responsive';

.home-projects {
  margin: 0 auto 80px;
  max-width: #{(1195px + 16px + 16px)};
  padding: 0 16px;

  @include responsive.target(md, xl) {
    margin-bottom: 100px;

    .project_list {
      margin-bottom: 0;
    }

    .project_list__item {
      margin-bottom: 100px;
    }
  }

  div.project_list {
    text-align: center;
    display: block;
    margin: 30px;
  }

  .h2 {
    text-align: center;

    @include responsive.target(xs, sm) {
      max-width: 288px;
      text-align: left;
      margin: 0 auto 30px;
      padding: 0 27px;
      background-image: url('/static/images/home-path/mobile-line.svg');
      background-size: 6px auto;
      background-position-x: 0;
      background-position-y: center;
      background-repeat: no-repeat;
    }
  }
}

.home-projects__btn {
  display: block;
  margin: 0 auto;
  min-width: 230px;
}
