@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.project-card-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
  @include responsive.target(md, xl) {
    gap: 45px;
    padding-bottom: 45px;
  }
}

.project-card {
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px #979797;
  .bottom-sheet & {
    border-radius: 0;
    box-shadow: none;
  }
  @include responsive.target(xs, sm) {
    .btn {
      font-size: 10px;
      line-height: 12px;
      padding: 6px 8px;
    }
  }
}

.project-card__draft-info {
  border-radius: 10px 10px 0px 0px;
  background: #e6e6e6;
  color: #979797;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: fonts.$fw-medium;
  line-height: 26px;
  @include responsive.target(md, xl) {
    font-size: 14px;
  }
}

.project-card__inner {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  .bottom-sheet & {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 12px;
    padding-right: 12px;
  }
  @include responsive.target(md, xl) {
    padding-top: 20px;
    padding-bottom: 35px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.project-card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.project-card__progress {
  margin-top: 25px;
  overflow: auto;
  margin-left: -15px;
  margin-right: -15px;
  @include responsive.target(md, xl) {
    margin-left: 0;
    margin-right: 0;
  }
}

.project-card__header-column {
  display: flex;
  flex-direction: column;
  gap: 15px;
  &.project-card__header-column--countdown {
    display: none;
    @include responsive.target(md, xl) {
      display: flex;
      min-width: 150px;
    }
  }
}

.project-card__meta {
  color: config.$color-black;
  font-size: 10px;
  font-style: normal;
  font-weight: fonts.$fw-light;
  line-height: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include responsive.target(md, xl) {
    .mobile-only {
      display: none;
    }
  }
  &.project-card__meta--right {
    justify-content: flex-end;
  }
  @include responsive.target(md, xl) {
    font-size: 14px;
  }
}

.project-card__title {
  color: config.$color-black;
  font-size: 14px;
  font-style: normal;
  font-weight: fonts.$fw-medium;
  line-height: normal;
  @include responsive.target(md, xl) {
    font-size: 22px;
  }
}

.project-card__headline {
  color: config.$color-black;
  font-size: 12px;
  font-style: normal;
  font-weight: fonts.$fw-medium;
  line-height: normal;
  @include responsive.target(md, xl) {
    margin-top: 15px;
    font-size: 22px;
  }
}

.project-card__action-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  &.project-card__action-row--distributed {
    justify-content: space-between;
  }
  &.project-card__action-row--right {
    justify-content: flex-end;
  }
  .btn + .btn {
    margin-left: 0;
  }
  @include responsive.target(md, xl) {
    gap: 20px;
  }
}

.project-card__text {
  color: config.$color-black;
  font-size: 10px;
  font-style: normal;
  font-weight: fonts.$fw-light;
  line-height: 17px; /* 143.75% */
  strong {
    font-weight: fonts.$fw-medium;
  }
  &.project-card__text--no-wrap {
    white-space: nowrap;
  }
  @include responsive.target(md, xl) {
    font-size: 16px;
    line-height: 23px; /* 143.75% */
  }
}

.project-card__key-data {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  @include responsive.target(md, xl) {
    flex-wrap: wrap;
    gap: 25px;
  }
}

.project-card__key-data-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @include responsive.target(md, xl) {
    gap: 6px;
  }
}

.project-card__key-data-label {
  color: config.$color-black;
  font-size: 10px;
  font-style: normal;
  font-weight: fonts.$fw-bold;
  line-height: normal;
  @include responsive.target(md, xl) {
    font-size: 14px;
  }
}

.project-card__key-data-value {
  color: config.$color-black;
  font-size: 9px;
  font-style: normal;
  font-weight: fonts.$fw-light;
  line-height: normal;
  white-space: wrap;
  @include responsive.target(md, xl) {
    font-size: 12px;
  }
}

.project-card__countdown {
  color: config.$color-black;
  text-align: right;
  font-size: 22px;
  font-style: normal;
  font-weight: fonts.$fw-bold;
  line-height: 25.5px; /* 115.909% */
}

.project-card__date {
  color: config.$color-black;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: fonts.$fw-light;
  line-height: 25.5px; /* 182.143% */
}

.project-card__toggler {
  display: none;
  @include responsive.target(md, xl) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 36px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    transition: background-color 0.15s ease-in-out;
    &:hover {
      background-color: rgba(config.$color-mercury, 0.5);
    }
  }
}

.project-card__details {
  height: 0;
  transition: height 0.3s ease-in-out;
  overflow: hidden;
  .project-card--always-expanded & {
    height: auto;
    overflow: initial;
  }
}

.project-card__details-inner {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  gap: 15px;
  @include responsive.target(md, xl) {
    gap: 20px;
    padding-top: 35px;
  }
}

.project-card__toggler-icon {
  font-family: config.$font-Icons;
  color: config.$primary-color;
  font-size: 30px;
  transform: rotate(90deg);
  transition: transform 0.3s ease-in-out;

  .project-card--expanded & {
    transform: rotate(-90deg);
  }
}

.project-card__row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  @include responsive.target(md, xl) {
    gap: 20px;
  }
}

.project-card__column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 auto;
  @include responsive.target(md, xl) {
    gap: 20px;
  }
}

.project-card__social-media {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.project-card__social-media__link {
  display: inline-block;
  text-decoration: none;
}

.project-cart__action {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 12px;
  .btn {
    font-size: 14px;
    line-height: 18px;
    padding: 6px 15px 6px;
  }
  @include responsive.target(md, xl) {
    display: none;
  }
}

.project-card__table {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @include responsive.target(md, xl) {
    gap: 20px;
  }
}
