@use 'sass:math';
@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

$graphicTransition: transform 400ms ease-in-out;

.project_list {
  display: flex;

  .button-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    flex-wrap: wrap;

    .btn {
      margin: 0 !important;
    }
  }

  &--related {
    margin-bottom: 0;
  }

  &--random {
    margin-bottom: 0;
  }

  &--empty {
    flex-direction: column;

    .text {
      text-align: center;
    }
  }

  &--no-margin {
    margin-bottom: 0;
  }

  @include responsive.target(xs, sm) {
    flex-direction: column;
    align-items: center;
  }

  @include responsive.target(md) {
    justify-content: space-between;
    max-width: 758px;
    margin: 0 auto;
  }

  @include responsive.target(md, xl) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 140px;
  }
}

.project_list__item {
  max-width: 320px;

  @include responsive.target(md) {
    margin-bottom: 120px;
  }

  @media (min-width: 992px) and (max-width: 1090px) {
    width: 28.541226216%;
    margin-right: 7.188160677%;
  }

  @include responsive.target(lg, xl) {
    width: 26.7558528428%;
    margin-bottom: 120px;
    margin-right: 9.8662207358%;

    .bookable-projects & {
      width: auto;
      margin-bottom: 0;
      margin-right: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .project_list--animated & {
    opacity: 1;
    transform: translateY(0);
    transition-property: opacity, transform;
    transition-duration: 500ms;

    @for $i from 2 through 20 {
      &:nth-child(#{$i}) {
        transition-delay: #{($i - 1) * 100ms};
      }
    }
  }

  .project_list--animated.project_list--before-animation-start & {
    opacity: 0;
    transform: translateY(200px);
  }

  .project_list--related & {
    margin-bottom: 80px;
  }
}

.bookable-projects {
  .project_list:not(.project-list) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

    @media (max-width: 500px) {
      grid-template-columns: 1fr;
    }
  }

  .project_list.project_list--no-results:not(.project-list) {
    grid-template-columns: 1fr;
  }

  .project_list__item {
    @media (max-width: 1090px) {
      width: auto;
      max-width: unset;
      margin-right: 0;
    }
  }

  .bookable-projects {
    max-width: none;
    margin-bottom: 0;
  }

  @include responsive.target(md) {
    margin-bottom: 120px;

    .bookable-projects {
      margin-bottom: 0;
    }
  }

  .bookable-projects {
    @include responsive.target(md, xl) {
      column-gap: 30px;
      row-gap: 44px;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 52px;
    }
  }
}

.project_teaser {
  color: config.$color-text-black;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-decoration: none;
  margin-bottom: 80px;

  @include responsive.target(xs, xl) {
    margin-bottom: 0;
  }
}

.project_teaser {
  .project_teaser__info {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    @include responsive.target(md, xl) {
      min-height: 84px;
      justify-content: flex-end;
    }

    &.project_teaser__info {
      gap: 10px;
    }
  }

  .project_teaser__location {
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;

    @include responsive.target(md, xl) {
      margin-bottom: 0;
    }
  }

  .project_teaser__type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;

    @include responsive.target(md, xl) {
      margin-bottom: 0;
    }

    .project-type__text {
      font-size: 16px;
      line-height: 1.3;
      margin-bottom: 0;
      max-height: ceil(16px * 1.3) * 2;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .location__text {
    font-size: 16px;
    line-height: 1.3;
    margin-bottom: 0;
    max-height: ceil(16px * 1.3) * 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .location__icon {
    font-size: 16px;
    margin-right: 10px;

    @include responsive.target(md, xl) {
      font-size: 18px;
      vertical-align: middle;
      line-height: 26px;
      margin-right: 5px;
    }
  }

  .project_teaser__date {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;

    @include responsive.target(md, xl) {
      height: 32px;
    }
  }
  .date__icon {
    font-size: 16px;
    margin-right: 10px;
  }
  .date__text {
    font-size: 16px;
    line-height: 1.3;
    margin-bottom: 0;
    max-height: ceil(16px * 1.3) * 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.project_teaser__title {
  min-height: 80px;
  font-size: 24px;
  line-height: 1.25;
  font-weight: fonts.$fw-medium;
  padding-bottom: 5px;
  display: flex;
  align-items: center;

  @include responsive.target(md, xl) {
    min-height: 56px;
    font-size: 22px;
    line-height: 1.27;
    padding-bottom: 0;
  }

  span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 60px;
  }
}

.image_box {
  position: relative;
  margin-right: 20px;
  margin-bottom: 40px;

  &:before {
    content: '';
    position: absolute;
    right: -20px;
    bottom: -20px;
    height: 100%;
    width: 50%;
  }

  &--fern {
    &:before {
      background-color: config.$color-fern;
    }
  }

  &--sand {
    &:before {
      background-color: config.$color-sand;
    }
  }

  &--terracotta {
    &:before {
      background-color: config.$color-terracotta;
    }
  }

  &--pine {
    &:before {
      background-color: config.$color-pine;
    }
  }

  &--chocolate {
    &:before {
      background-color: config.$color-chocolate;
    }
  }

  &--chestnut {
    &:before {
      background-color: config.$color-chestnut;
    }
  }

  &--olive {
    &:before {
      background-color: config.$color-olive;
    }
  }

  @include responsive.target(md, xl) {
    margin-bottom: 30px;
  }
}

.project_teaser__image-wrapper {
  .bookable-projects & {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding-top: calc(400 / 364 * 100%);
  }
}

.image_box__image {
  position: relative;
  display: block;
  width: 100%;

  .bookable-projects & {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.image_box__info {
  position: absolute;
  bottom: 0;
  left: 0;
}

.project_teaser__description {
  font-size: 16px;
  font-weight: fonts.$fw-light;
  line-height: 1.375;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 130px;

  @include responsive.target(md, xl) {
    max-height: 3 * ceil(16px * 1.375);
  }
}

.info {
  width: 100%;
  display: flex;
  flex-direction: column;

  height: 100%;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transform: scaleY(0.375);
    transform-origin: 0 100%;
    background-image: linear-gradient(transparent, config.$color-text-black);
    transition: transform 400ms ease-in-out;
  }

  @include responsive.target(md, xl) {
    .project_teaser--hover &,
    .project_teaser:hover & {
      height: 100%;

      &:before {
        transform: scaleY(1);
      }
    }
  }
}

.info__graphic.project-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  width: #{(math.div(236, 300) * 100) + 0%};
  height: #{(math.div(236, 400) * 100) + 0%};
  color: config.$color-white;

  transform-origin: 0 100%;
  transform: translateX(#{(math.div(10, 236) * 100) + 0%})
    translateY(-#{(math.div(10, 236) * 100) + 0%}) scale(0.3601694915);

  .project_teaser & {
    .project-progress__text {
      fill: config.$color-white;
    }
  }

  .info_box__final__icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .icon {
      font-size: 100px;
      line-height: 100px;
    }
  }

  @include responsive.target(md, xl) {
    transition-property: transform;
    transition-duration: 400ms;
    transition-timing-function: ease-in-out;
    color: config.$color-white;

    .project_teaser--hover &,
    .project_teaser:hover & {
      transform: translateX(#{(math.div(32, 236) * 100) + 0%})
        translateY(-#{(math.div(137, 236) * 100) + 0%}) scale(1);
    }
  }
}

.info__icon {
  position: absolute;
  opacity: 1;
  right: 10px;
  bottom: 10px;
  transition: opacity 400ms ease-in-out;
}

@include responsive.target(md, xl) {
  .project_teaser--hover,
  .project_teaser:hover {
    .info__icon {
      opacity: 0;
    }
  }
}

.info__details {
  @include responsive.target(xs, sm) {
    display: none;
  }

  display: flex;
  flex-direction: row;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 130px;
  overflow: hidden;
}

.info__details__text {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  transition-property: transform, opacity;
  transition-duration: 400ms;
  transition-delay: 0ms;
  transition-timing-function: ease-in-out;
  transform-origin: 0 0;
  transform: translateY(100%);
  opacity: 0;
  color: config.$color-white;
  // font-weight: fonts.$fw-bold;
  font-size: 20px;
  padding: 0px 50px;
  text-align: center;
  line-height: 1.2;

  .project_teaser--hover &,
  .project_teaser:hover & {
    transform: translateY(0);
    opacity: 1;
  }
}

.details__volunteers {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  align-items: center;
  transition-property: transform, opacity;
  transition-duration: 400ms;
  transition-delay: 0ms;
  transition-timing-function: ease-in-out;
  transform-origin: 0 0;
  transform: translateY(100%);
  opacity: 0;

  .project_teaser--hover &,
  .project_teaser:hover & {
    transform: translateY(0);
    opacity: 1;
  }
}

.volunteers__icon {
  margin-bottom: 9px;
}

.details__days_until {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 50%;
  transition-property: transform, opacity;
  transition-duration: 400ms;
  transition-timing-function: ease-in-out;
  transform-origin: 0 0;
  transition-delay: 0ms;
  opacity: 0;
  transform: translateY(100%);

  .project_teaser--hover &,
  .project_teaser:hover & {
    opacity: 1;
    transform: translateY(0);
  }
}

.volunteers__countdown,
.days_until__countdown {
  color: config.$color-white;
  font-size: 20px;
  line-height: 28px;
}

.volunteers__text,
.days_until__text {
  font-size: 15px;
  color: config.$color-white;
  text-align: center;
  line-height: 22px;
  margin-bottom: 28px;
  max-width: 20ch;
}

.days_until__icon {
  margin-bottom: 9px;
}

.info_box__graphic {
  width: 236px;
  height: 236px;
}
