@use 'scss/config';
@use 'libs/styles/fonts';

.active-filters {
  overflow: hidden;
  color: config.$color-text-black;
}

.active-filters__inner {
}

.active-filters__list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.active-filters__item {
}

.active-filter {
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  background-color: config.$color-straw;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 8px;
  gap: 6px;
}

.active-filter__text {
  font-size: 12px;
  line-height: 1.33;
}

.active-filter__x {
  text-decoration: none;
  color: config.$color-curry;
}

.active-filter__icon {
  font-size: 16px;
}

.active-filters__none {
  display: block;
  font-size: 11px;
  line-height: 1.45;
}
