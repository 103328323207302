@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.user-projects {
}

.user-projects__filter-section {
  margin-bottom: 16px;

  @include responsive.target(md, xl) {
    margin-bottom: 32px;
  }
}

.user-projects__recurring-hint {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 300;
  line-height: 1.5;
  margin: 16px 0;
  color: config.$color-text-black;

  .icon {
    margin-right: 5px;
    transform: translateY(-3px);
  }

  @include responsive.target(xs, md) {
    align-items: flex-start;
    line-height: 1.25;

    .icon {
      margin-right: 10px;
    }
  }
}

.user-projects__stats {
  margin-bottom: 32px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.user-projects__assignments {
  margin-bottom: 32px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.user-projects__add-button {
  margin-top: 16px;
  margin-bottom: 16px;
}

.add-assignment-report-button {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  gap: 8px;
  text-decoration: none;
  color: config.$color-cod;
}

.add-assignment-report-button__icon {
  margin-bottom: 4px;
  align-self: flex-start;
}

.add-assignment-report-button__text {
  font-weight: fonts.$fw-light;
  line-height: 1.5;
}
