@use 'scss/config';
@use 'libs/styles/responsive';

.readonly-input {
  font-size: 16px;
  line-height: 1.5;
  width: 100%;
  height: 100%;
  padding-top: 13px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 11px;
  background-color: config.$color-mercury;
  border: 1px solid config.$color-input-border-default;
  border-radius: 2px;
}
