@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }
  @return $number;
}

@function rem($font-size, $base: $font-size-xl) {
  @return #{strip-unit($font-size) / strip-unit($base)}rem;
}

@mixin clearfix {
  &:after {
    content: '.';
    clear: both;
    display: block;
    visibility: hidden;
    height: 0;
  }
}

@mixin smooth-scrolling {
  -webkit-overflow-scrolling: touch;
}

@mixin visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
}

@mixin headerTransition {
  transition-property: transform;
  transition-duration: 400ms;
  transition-timing-function: ease-in-out;
}
