@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.employee-detail {
}

.employee-detail__top {
  margin-bottom: 32px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.employee-detail__meta {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  @include responsive.target(md, xl) {
    margin-bottom: 0;
  }
}

.employee-detail-meta {
  display: flex;
}

.employee-detail-meta__label {
  font-size: 16px;
  line-height: 1.5;
  font-weight: fonts.$fw-light;
  margin-right: 8px;
}

.employee-detail-meta__value {
  font-size: 16px;
  line-height: 1.5;
  font-weight: fonts.$fw-medium;
}

.employee-detail__stats {
  margin-bottom: 32px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.employee-detail__assignments {
  margin-bottom: 32px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.employee-detail__open-assignments {
  margin-bottom: 32px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.employee-detail__past-assignments {
}

.employee-detail__actions {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsive.target(md, xl) {
    flex-direction: row;
    gap: 14px;
  }
}

.employee-detail__action {
  @include responsive.target(md, xl) {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 0;
  }
}

.employee-detail-action {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsive.target(md, xl) {
    gap: 16px;
  }
}

.employee-detail-action__button {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;

  @include responsive.target(md, xl) {
    width: auto;
  }
}

.employee-detail-action__hint {
  flex-basis: 0;
  flex-grow: 0;
  flex-shrink: 1;
  font-size: 12px;
  line-height: 1.4;
  color: config.$color-carbon;
  margin-bottom: 8px;

  @include responsive.target(md, xl) {
    font-size: 14px;
    margin-bottom: 0;
  }
}
