@use 'scss/config';

.modal-outlet {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: config.$z-index-modal-outlet;
  pointer-events: none;
}

.modal-outlet__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(config.$color-cod, 0.8);
  transition: opacity ease 0.3s;
  opacity: 0;
  pointer-events: none;

  .modal-outlet--open & {
    pointer-events: all;
    opacity: 1;
  }
}

.modal-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: all;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-wrapper__backdrop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
