@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.wizard-container {
  max-width: 700px;
  margin: auto;
}

.wizard-header {
  position: relative;
}

.wizard-title {
  margin-bottom: 40px;

  @include responsive.target(md, xl) {
    margin-bottom: 60px;
  }
}

.wizard-header__step {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: fonts.$fw-light;
  font-size: 18px;
  color: config.$color-black;

  @include responsive.target(md, xl) {
    font-size: 28px;
    line-height: 34px;
  }
}

.wizard-actions {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 14px 16px;
  background-color: config.$color-honey;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  @include responsive.target(md, xl) {
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: calc((100% - 700px) * 0.5);
    padding-right: calc((100% - 700px) * 0.5);
  }
}
