@use 'scss/config';
@use 'libs/styles/responsive';

.home-container {
  // display: table;
  // table-layout: fixed;
  // display: flow-root;
}

.home-intro {
  margin: 0 auto;
  padding: 70px 0 100px 40px;
  padding-left: 20px;
  max-width: 320px;

  background-repeat: no-repeat;

  @include responsive.target(xs, sm) {
    background-image: url('/static/images/home-path/mobile-center-left.svg'),
      url('/static/images/home-path/mobile-left-center.svg'),
      url('/static/images/home-path/mobile-line.svg');
    background-size: auto, auto, 6px 76%;
    background-position-x: 0px, -1px, 0px;
    background-position-y: top, bottom, center;
    background-repeat: no-repeat, no-repeat, repeat-y;
  }

  @include responsive.target(md, xl) {
    background-image: url('/static/images/home-path/desktop-left-center.svg'),
      url('/static/images/home-path/desktop-line.svg');
    background-position-x: center;
    background-size: auto, 10px 100%;
    max-width: 1227px;
    width: 100%;
    margin: 0 auto;
    padding: 120px 0 200px;
  }
}

.home-intro__inner {
  display: flex;
  flex-direction: column;

  @include responsive.target(md, xl) {
    max-width: 1227px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
}

.home-intro__column {
  display: flex;
  flex-direction: column;

  @include responsive.target(xs, sm) {
    margin-bottom: 40px;
  }

  @include responsive.target(md, xl) {
    padding: 0 16px;

    max-width: 540px;
    flex: 0 1 540px;

    &--no-title {
      padding-top: 98px;
    }

    ul {
      margin-bottom: 20px;
    }

    p.text--small {
      margin-bottom: 10px;
    }
  }
}

.home-intro__spacer {
  height: 40px;
  @include responsive.target(md, xl) {
    height: 54px;
  }
}

.home-end {
  height: 560px;
  display: flex;
  justify-content: center;
}

.home-end__svg {
  height: 100%;
  height: max-content;
}
