@use 'scss/config';
@use 'libs/styles/responsive';

.company-registration {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.company-registration__text {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
}

.company-registration__steps {
  margin-bottom: 16px;
}

.company-registration-step {
  &.company-registration-step--3 {
    padding-bottom: 16px;
  }

  @include responsive.target(md, xl) {
    &.company-registration-step--3 {
      padding-bottom: 68px;
    }
  }
}

.company-registration__row {
  margin-bottom: 16px;

  &.company-registration__row--columns {
    display: flex;
    width: 100%;
    gap: 16px;
  }
}

.company-registration__select-row {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    margin-bottom: 48px;
  }
}

.company-registration__checkbox-row {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    margin-bottom: 20px;
  }
}

.company-registration-step__column {
  flex-grow: 1;

  &.company-registration-step__column--post-code {
    flex-grow: 0;
    width: 145px;
  }
}

.company-registration__action-row {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;

  @include responsive.target(md, xl) {
  }
}

.company-registration__action {
}
