@use 'scss/config';
@use 'libs/styles/responsive';

.copy-field {
  display: flex;
  font-size: 24px;
  margin-bottom: 16px;
  flex-direction: column;
  gap: 16px;

  @include responsive.target(sm, xl) {
    flex-direction: row;
  }
}

.copy-field__input {
  border: 1px solid config.$color-input-border-default;
  border-radius: 2px;
  font-size: 16px;
  padding-top: 11px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 13px;
  flex-grow: 1;

  @include responsive.target(md, xl) {
    font-size: 18px;
  }
}

.copy-field__button {
  flex-shrink: 0;
  width: 100%;

  @include responsive.target(sm, xl) {
    width: auto;
  }
}
