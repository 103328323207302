@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.bookable-projects__hint--bold {
  font-weight: fonts.$fw-medium;
}

.bookable-projects__message {
  margin-bottom: 32px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.bookable-projects__no-results {
  color: config.$color-carbon;
  line-height: 1.6em;
}
