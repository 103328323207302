@use 'scss/config';
@use 'libs/styles/responsive';
@use 'libs/styles/fonts';

.radio-boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;

  @include responsive.target(md, xl) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.radio-boxes__item {
  margin-bottom: 10px;
  &.radio-boxes__item--fill-width {
    flex: 1 1 auto;
  }
}

.radio-box {
  display: flex;
  width: 100%;
  max-width: 303px;
  padding: 20px 20px 20px 10px;
  position: relative;
  background-color: config.$color-straw;
  border-radius: 4px;
  margin-bottom: 10px;
  height: 100%;
  .radio-boxes__item--fill-width & {
    max-width: none;
  }
  &:first-child {
    margin-right: 10px;
  }

  @include responsive.target(md, xl) {
    padding: 20px 20px 20px 20px;
  }

  &.radio-box--selected {
    background-color: config.$color-curry;
  }
}

.radio-box__inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  padding-left: 32px;
  // margin-bottom: 12px;
  width: 100%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  line-height: 24px;

  &.radio-box__inner--select {
    padding-left: 0;

    &::before {
      content: '';
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      background: config.$color-mercury;
      opacity: 0;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }
}

.radio-box__label {
  position: relative;
}

.radio-box__row {
  display: block;
  margin-bottom: 10px;
  strong {
    font-weight: fonts.$fw-medium;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

.radio-box__subfield {
  display: block;
  margin-top: 5px;
  margin-bottom: 0;
  flex-basis: 100%;

  @include responsive.target(md, xl) {
    margin-top: 15px;
  }
}

.radio__checkmark {
  .radio-box:hover & {
    background-color: config.$color-stone;
  }

  .radio__input:checked + & {
    &:after {
      display: block;
      forced-color-adjust: none;

      @media (forced-colors: active) {
        background: config.$color-white;
      }
    }
  }
}
