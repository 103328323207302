@use 'scss/config';
@use 'libs/styles/fonts';

.project-progress {
  position: relative;
  display: inline-block;
  width: 236px;
  height: 236px;
  color: config.$color-cod;
}

.project-progress__svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.project-progress__text {
  position: absolute;
  left: 50%;
  top: 50%;
  font-weight: fonts.$fw-medium;
  font-size: 45px;
  color: inherit;
  letter-spacing: 0;
  text-align: center;
  line-height: 42px;
  forced-color-adjust: initial;
}
