@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.home-engagement {
  padding: 0 0 270px;
  background-repeat: no-repeat;
  background-image: url('/static/images/home-path/mobile-center-left-2.svg'),
    url('/static/images/home-path/mobile-line.svg');
  background-size: auto, 10px 100%;
  background-position-x: center;
  background-position-y: bottom, center;
  background-repeat: no-repeat;

  .cic-overview & {
    padding-bottom: 0;
  }

  @include responsive.target(md, xl) {
    background-image: url('/static/images/home-path/mobile-line.svg');
    background-size: 10px 100%;
    background-position-x: center;
    background-position-y: center;
    padding: 0 0 140px;
  }

  &--no-line {
    background-image: unset;
  }
}

.home-engagement__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 0 20px;
  margin: 0 auto;

  .cic-overview & {
    max-width: none;
    padding-left: 0;
    padding-right: 0;
    padding-top: 5px;
  }

  @include responsive.target(lg, xl) {
    max-width: 1235px;
    flex-direction: row;
    justify-content: space-around;
  }
}

.home-engagement__container {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  position: relative;
  padding-bottom: 74px;
  text-decoration: none;
  margin-bottom: 98px;
  justify-content: center;
  align-items: center;

  .cic-overview & {
    max-width: none;
    margin-bottom: 32px;
    padding-bottom: 5px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    margin-bottom: 100px;
  }

  @include responsive.target(lg, xl) {
    margin-bottom: 0;
    padding-bottom: 0;
    max-width: 474px;
  }
}

.home-engagement__container--text-left {
  align-items: flex-start;

  .cic-overview & {
    padding-bottom: 0;
  }

  @include responsive.target(lg, xl) {
    .cic-overview & {
      padding-left: 44px;
    }
  }
}

.home-engagement__title {
  font-size: 28px;
  color: config.$color-cod;
  font-weight: fonts.$fw-light;
  text-align: center;
  line-height: 1.2;
  text-shadow: 0 0 6px config.$color-white;
  background-color: config.$color-white;
  padding: 20px 0;
  margin: 0 10px;

  @include responsive.target(md, xl) {
    font-size: 48px;
    padding: 20px 0 30px;
  }
}

.home-engagement__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 273px;

  .cic-overview & {
    transform: translate(-7px, -5px);
  }

  @include responsive.target(md, lg) {
    max-height: 380px;
  }

  @include responsive.target(xl) {
    max-height: 460px;

    .cic-overview & {
      transform: none;
    }
  }
}

.home-engagement__btn-box {
  position: relative;
  top: -200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  opacity: 1;
  color: config.$color-white;

  @include responsive.target(md, xl) {
    width: 210px;
  }

  @include responsive.target(md, lg) {
    top: -274px;
  }

  @include responsive.target(xl) {
    top: -284px;
  }

  @include responsive.target(md, lg) {
    bottom: -40px;
  }

  @include responsive.target(xl) {
    bottom: 43px;
  }
}

.home-engagement__icon {
  font-size: 82px;
  line-height: 82px;
  margin-bottom: 10px;

  @include responsive.target(md, xl) {
    font-size: 120px;
    line-height: 120px;
    margin-bottom: 20px;
  }
}

.home-engagement__btn {
  width: 100%;
}

.home-engagement__text {
  font-weight: fonts.$fw-medium;
  font-size: 24px;
  line-height: 30px;

  @include responsive.target(md, xl) {
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0;
  }
}

.home-engagement__image-box {
  display: block;
  border-radius: 50%;
  height: 273px;
  width: 273px;
  flex: 0 0 auto;

  @include responsive.target(md, lg) {
    width: 380px;
    height: 380px;
  }

  @include responsive.target(lg, xl) {
    transform: translateX(-10px);
  }

  @include responsive.target(xl) {
    width: 460px;
    height: 460px;
  }

  &::before {
    position: relative;
    width: 100%;
    height: 0;
    content: '';
    display: block;
    padding-bottom: 100%;
    border-radius: 50%;
    background-color: config.$color-honey;
    transform: translate(15px, 10px);

    @include responsive.target(md, lg) {
      transform: translate(20px, 10px);
    }

    @include responsive.target(xl) {
      transform: translate(20px, 10px);
    }
  }
}

.home-engagement__image {
  position: relative;
  top: -273px;
  display: block;
  border-radius: 50%;
  height: 273px;
  width: 273px;
  flex: 0 0 auto;

  @include responsive.target(md, lg) {
    height: 380px;
    width: 380px;
    top: -380px;
  }

  @include responsive.target(xl) {
    height: 460px;
    width: 460px;
    top: -460px;
  }
}

.image-volunteering {
  background-image: url('/static/images/home-engagieren.jpg');
  background-size: 100%;
  background-position-x: 50%;
  background-position-y: 50%;
}

.image-project-submission {
  background-image: url('/static/images/home-einreichen.jpg');
  background-size: 100%;
  background-position-x: 50%;
  background-position-y: 50%;
}

.image-cic {
  background-image: url('/static/images/cic-overview.png');
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 50%;
}
