@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.flyout {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  user-select: none;

  &--left {
    flex-direction: row-reverse;
  }

  &--right {
    flex-direction: row;
  }
}

.flyout__label {
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: fonts.$fw-regular;
  text-decoration: underline;
  color: config.$color-cod;

  @include responsive.target(md, lg) {
    font-size: 16px;
    line-height: 22px;
  }
}

.flyout__button {
  cursor: pointer;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  padding: 0;
  user-select: none;

  .flyout--left & {
    margin-right: 10px;
  }

  .flyout--right & {
    margin-left: 10px;
  }

  .flyout--open & {
    &::after {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      content: '';
      z-index: 9999;
    }
  }
}

.flyout__content {
  display: none;
  position: absolute;
  z-index: 100;
  min-width: 250px;
  max-width: 450px;
  padding: 8px 12px;
  border-radius: 2px;
  background-color: config.$color-flyout;
  font-weight: fonts.$fw-regular;
  font-size: 14px;
  color: config.$color-cod;
  letter-spacing: 0;
  line-height: 18px;
  box-shadow: 0 8px 16px 0 rgba(102, 102, 102, 0.06);

  .flyout--open & {
    display: block;
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .flyout--right & {
    right: -4px;

    &:after {
      right: 8px;
    }
  }

  .flyout--left & {
    left: -4px;

    &:after {
      left: 8px;
    }
  }

  .flyout--bottom & {
    top: calc(100% + 13px);

    &:after {
      border-width: 0 8px 8px 8px;
      border-color: transparent transparent config.$color-flyout transparent;
      bottom: 100%;
    }
  }

  .flyout--top & {
    bottom: calc(100% + 13px);

    &:after {
      border-width: 0 8px 8px 8px;
      border-width: 8px 8px 0 8px;
      border-color: config.$color-flyout transparent transparent transparent;
      top: 100%;
    }
  }

  // display: none;
}
