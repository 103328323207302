@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.project-overview-teaser {
  position: relative;
  padding-right: 16px;
  padding-bottom: 20px;

  @include responsive.target(lg, xl) {
    padding-right: 18px;
  }
}

.pot__background {
  position: absolute;
  z-index: 0;
  top: 20px;
  right: 0;
  bottom: 0;
  width: 40%;
  background-color: config.$color-honey;
}

.pot__foreground {
  position: relative;
  z-index: 10;
  background-color: config.$color-straw;
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr;

  @include responsive.target(lg, xl) {
    padding-top: 40px;
    padding-left: 36px;
    padding-right: 52px;
    grid-template-columns: 1fr 1fr;
  }
}

.pot__main-info {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 2px solid #bebebe;

  @include responsive.target(lg, xl) {
    grid-column: 2;
  }
}

.pot__title {
  font-size: 18px;
  line-height: 1.44;
  font-weight: fonts.$fw-medium;
  margin-bottom: 16px;

  @include responsive.target(md, xl) {
    font-size: 22px;
    line-height: 1.45;
  }
}

.pot__date-time {
  font-size: 14px;
  line-height: 1.28;
  margin-bottom: 16px;

  @include responsive.target(md, xl) {
    font-size: 16px;
    line-height: 1.375;
  }
}

.pot__date,
.pot__time {
  font-size: inherit;
  line-height: inherit;
}

.pot__add-to-calendar {
  
}

.pot__address {
  font-size: 14px;
  line-height: 1.28;
  margin-top: 32px;
  margin-bottom: 16px;

  @include responsive.target(md, xl) {
    font-size: 16px;
    line-height: 1.375;
  }
}

.pot__progress {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 2px solid #bebebe;
  &.pot__progress--corporate {
    display: none;
  }

  @include responsive.target(lg, xl) {
    position: absolute;
    grid-row: 1;
    grid-column: 1;
    border-bottom: 0;
  }
}

.pot-progress {
}

.pot-progress__graph {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.pot-progress__values {
  display: flex;
  gap: 20px;
}

.pot-progress__value {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.pot-progress-value {
  text-align: center;
}

.pot-progress-value__icon {
  display: inline-block;
  margin-bottom: 8px;
}

.pot-progress-value__value {
  font-size: 16px;
  line-height: 1.5;
  font-weight: fonts.$fw-medium;
}

.pot-progress-value__text {
  font-size: 12px;
  line-height: 1.33;
}

.pot__visual {
  display: none;

  @include responsive.target(lg, xl) {
    &.pot__visual--corporate {
      background-color: rgba(config.$color-black, 0.1);
      width: 253px;
      height: 338px;
      display: block;
      position: absolute;
      grid-row: 1;
      grid-column: 1;
      border-bottom: 0;
    }
  }
}

.pot-visual {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.pot__actions {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 2px solid #bebebe;

  @include responsive.target(lg, xl) {
    grid-column: 2;
  }
}

.pot__actions-title {
  font-size: 18px;
  line-height: 1.44;
  font-weight: fonts.$fw-medium;
  margin-bottom: 16px;

  @include responsive.target(md, xl) {
    font-size: 22px;
    line-height: 1.45;
  }
}

.pot__action-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.pot__social-media {
  @include responsive.target(lg, xl) {
    grid-column: 2;
  }
}

.pot__social-media-text {
  font-size: 14px;
  line-height: 1.28;
  margin-bottom: 16px;
}

.pot__booking-info {
  @include responsive.target(lg, xl) {
    grid-column: 2;
  }
}

.pot__booking-address {
  margin-bottom: 18px;
}

.pot-key-values {
  font-size: 14px;
  line-height: 1.28;

  @include responsive.target(md, xl) {
    font-size: 16px;
    line-height: 1.375;
  }
}

.pot-key-values__row {
  display: flex;
  align-items: center;
  gap: 4px;

  &.pot-key-values__row--column {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

.pot-key-values__row--top {
  align-items: flex-start;
}

.pot-key-values__key {
  font-size: inherit;
  line-height: inherit;
  font-weight: fonts.$fw-medium;
  white-space: nowrap;
}

.pot-key-values__value {
  font-size: inherit;
  line-height: inherit;

  a {
    color: inherit;
    text-decoration: none;
  }
}

.pot-billing-address__address {
  display: flex;
  flex-direction: column;
}

.pot-undercover-link {
  color: inherit;
  text-decoration: none;
}

.pot__bookable-details {
  @include responsive.target(lg, xl) {
    grid-column: 2;
  }
}
