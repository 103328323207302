@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.profile-navigation {
  background-color: config.$color-white;
  padding-top: calc(#{config.$header-height--mobile} + 80px);
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 56px;
  width: 100%;

  @include responsive.target(md, xl) {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

.profile-navigation__header {
}

.profile-nav-section {
  margin-bottom: 56px;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.profile-nav-section__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  background-color: config.$color-curry;
  border-radius: 50%;
  margin-bottom: 16px;
  color: config.$color-white;
  font-family: config.$font-Icons;
  font-size: 30px;
}

.profile-nav-section__title {
  font-size: 22px;
  line-height: 1.45;
  font-weight: fonts.$fw-medium;
  color: config.$color-cod;
}

.profile-nav-section__list {
  @include responsive.target(md, xl) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.profile-nav-section__item {
  border-bottom: 2px solid config.$color-stone;

  @include responsive.target(md, xl) {
    border-bottom: 0;
  }
}

.profile-nav-item {
}

.profile-nav-item__link {
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 18px;
  line-height: 1.44;
  font-weight: fonts.$fw-light;
  color: config.$color-cod;
  text-decoration: none;
  background-color: unset;
  border: 0;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;

  .profile-nav-item--active & {
    font-weight: fonts.$fw-medium;
  }

  &::before {
    content: '';
    height: 15px;
    width: 8px;
    margin-left: 4px;
    margin-right: 16px;
    margin-bottom: 4px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg width='9' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.85595 8L0.419945 14.44L1.48595 15.5L7.91895 9.063L8.98095 8L7.91895 6.937L1.48595 0.5L0.418945 1.559L6.85595 8Z' fill='%23DBAA35'/%3E%3C/svg%3E");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform ease-out 0.2s;
  }

  .profile-nav-item--expanded &::before {
    transform: rotate(90deg);
  }

  @include responsive.target(md, xl) {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.profile-subnav {
  overflow: hidden;
  height: 0;
  opacity: 0;
}

.profile-subnav__list {
  padding-bottom: 8px;
  margin-top: -8px;

  @include responsive.target(md, xl) {
    margin-top: -4px;
    padding-bottom: 4px;
  }
}

.profile-subnav-item {
  padding-left: 29px;
  text-decoration: none;
  color: config.$color-cod;
  font-size: 18px;
  line-height: 1.44;
  font-weight: fonts.$fw-light;
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;

  &.profile-subnav-item--active {
    font-weight: fonts.$fw-medium;
  }

  @include responsive.target(md, xl) {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
