@use 'scss/config';
@use 'libs/styles/responsive';

.cic-overview {
}

.cic-overview__inner {
}

.cic-overview__title {
  margin-bottom: 32px;

  @include responsive.target(lg, xl) {
    margin-bottom: 56px;
  }
}

.cic-overview__section {
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }

  &.cic-overview__section--narrow {
    max-width: 962px;
    margin-left: auto;
    margin-right: auto;
  }

  @include responsive.target(lg, xl) {
    margin-bottom: 120px;
  }
}

.cic-overview__social-media {
  @include responsive.target(lg, xl) {
    margin-top: 48px;
  }
}

.cic-overview__accordion {
  margin-bottom: 24px;
}

.cic-overview__no-answers {
  font-size: 12px;
  line-height: 1.33;
  color: config.$color-smoke;
  margin-bottom: 12px;
}

.cic-overview__contact-link {
}
