@use 'config';
@use 'libs/styles/responsive';
@use 'libs/styles/helpers';

@use 'typography';

*,
*::before,
*::after {
  @media (prefers-reduced-motion: reduce) {
    transition: none !important;
    animation: none !important;
  }
}

html,
body {
  position: relative;
  min-height: 100vh;

  &.cke_editable {
    padding: 10px;
  }
}

.content-wrapper {
  display: block;
  margin: 0 auto;
  width: 100%;
  overflow-x: hidden;

  @include responsive.target(xs, sm) {
    padding: 68px 16px 160px;

    &--create-project-proposal {
      padding: 4px 16px 120px;
    }
  }

  @include responsive.target(md, xl) {
    max-width: #{(1195px + 16px + 16px)};
    padding: 94px 16px 200px;

    &--create-project-proposal {
      padding-bottom: 120px;
    }
  }
}

.content-wrapper__title {
  position: absolute;
  top: 0;
}

.separated-columns {
  display: flex;
  flex-direction: column;

  @include responsive.target(md, xl) {
    flex-direction: row;
    margin-bottom: 100px;
  }
}

.separated-columns__column {
  position: relative;
  display: block;

  @include responsive.target(xs, sm) {
    &:nth-child(odd) {
      padding-bottom: 50px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 2px;
        width: 100%;
        background: config.$color-silver;
        transform: translateY(50%);
      }

      &:before {
        content: attr(data-text);
        position: absolute;
        left: 50%;
        bottom: 0;
        background: config.$color-white;
        transform: translateY(50%) translateX(-50%);
        text-transform: uppercase;
        font-size: 16px;
        text-align: center;
        line-height: 22px;
        padding: 25px;
        z-index: 10;
      }
    }

    &:nth-child(even) {
      padding-top: 50px;
    }
  }

  @include responsive.target(md, xl) {
    flex: 1 1 50%;

    &:nth-child(odd) {
      padding-right: 75px;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 2px;
        background: config.$color-silver;
        transform: translateX(-50%);
      }

      &:before {
        content: attr(data-text);
        position: absolute;
        right: 0;
        top: 50%;
        background: config.$color-white;
        transform: translateY(-50%) translateX(50%);
        text-transform: uppercase;
        font-size: 18px;
        text-align: center;
        line-height: 26px;
        padding: 15px;
        z-index: 10;
      }
    }

    &:nth-child(even) {
      padding-left: 75px;
    }
  }
}

.spacer {
  flex: 1 1 auto;
}

.content-default {
  max-width: 1080px;

  &--center {
    margin: 0 auto;
  }
}

.content-narrow {
  margin: 0 auto;
  max-width: 965px;
  width: 100%;
}

.content-slim {
  margin: 0 auto;
  max-width: 650px;
  width: 100%;
}

.icon-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .icon-box {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.button-wrapper {
  justify-content: space-between;
  padding: 20px;

  &--straw {
    background-color: config.$color-straw;
  }
}

// A11y
// ---------------------------------------------
.sr-only {
  @include helpers.visually-hidden;
}

dd {
  @extend .text;
  margin-bottom: 0;
}

.responsive-debug {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 5px 10px;
  background: #3200ff;
  color: #ffffff;
  opacity: 0.8;
  font-size: 12px;
  font-weight: 500;
  z-index: 9999;

  &:before {
    @each $name, $width in config.$breakpoints {
      @include responsive.target($name) {
        content: 'Target: #{$name}';
      }
    }
  }
}
