@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.partner-list {
  padding-top: 30px;

  @include responsive.target(md, xl) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1060px;
    margin: 0 auto;
  }
}

.partner-teaser {
  margin-bottom: 50px;
  position: relative;

  @include responsive.target(md) {
    max-width: 400px;
    width: calc((100% - 49px) / 2);
    margin-right: 49px;

    &:nth-child(2n + 2) {
      margin-right: 0;
    }
  }

  @include responsive.target(lg, xl) {
    max-width: 320px;
    width: calc((100% - 98px) / 3);
    margin-right: 49px;

    &:nth-child(3n + 3) {
      margin-right: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.partner-teaser__image {
  width: 100%;
  height: 0;
  padding-bottom: 56.597222222%;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 80% auto;

  &--portrait {
    background-size: auto 80%;
  }

  @include responsive.target(md, xl) {
    padding-bottom: 56.5625%;
  }
}

.partner-teaser__name {
  font-weight: fonts.$fw-bold;
  font-size: 16px;
  color: config.$color-black;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 5px;
  max-width: 100%;
}

.partner-teaser__link {
  font-size: 16px;
  color: config.$color-curry;
  line-height: 22px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    margin-left: 5px;
  }
}
