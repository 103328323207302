@use 'scss/config';

.focus-layer {
  position: absolute;
  z-index: config.$z-index-focus-layer;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100vh;
  opacity: 0;
  transition: opacity ease 0.3s;
  overflow: hidden;

  &.focus-layer--visible {
    opacity: 1;
  }
}

.focus-indicator {
  position: absolute;
  top: 0;
  left: 0;
  outline: 2px solid config.$color-black;
  border: 2px solid config.$color-white;
  box-shadow: 0 0 20px 0 rgba(config.$color-black, 0.3);
}
