@use 'scss/config';
@use 'libs/styles/responsive';

#privacy-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: rgba(113, 113, 113, 0.95);
  padding: 1.25em 20px 0.9375em;
  z-index: config.$z-index-privacy-banner;

  @include responsive.target(md) {
    padding: 1.25em 40px;
  }

  @include responsive.target(lg) {
    padding: 1.25em 60px;
  }

  @include responsive.target(xl) {
    padding: 1.25em 187px;
  }
}

.privacy-content {
  width: 100%;
  display: flex;
  margin: 0 auto;
  align-items: center;

  @include responsive.target(xs, sm) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.privacy-content__text {
  font: 300 0.875em/1.14286 Frutiger, Arial, Helvetica, sans-serif;
  color: config.$color-white;
  padding-top: 0.32571em;
  padding-bottom: 0.30286em;

  a {
    color: inherit;
  }
}

.privacy-content__btn {
  display: block;
  background: #444;
  border: 1px solid transparent;
  border-radius: 2px;
  color: config.$color-white;
  padding: 0 20px;
  font: 300 1em/1.125 Frutiger, Arial, Helvetica, sans-serif;
  padding-top: 0.69875em;
  padding-bottom: 0.67625em;
  cursor: pointer;

  &:hover {
    background-color: #242424;
  }

  @include responsive.target(xs, sm) {
    margin-top: 0.9375em;
  }

  @include responsive.target(md) {
    min-width: 100px;
    margin-left: 40px;
  }

  @include responsive.target(lg, xl) {
    min-width: 100px;
    margin-left: 60px;
  }
}

body.privacy-banner-visible {
  footer > .wrapper {
    margin-bottom: 100px;

    @include responsive.target(xs) {
      margin-bottom: 150px;
    }
  }
}

.privacy-settings {
  margin: 30px 0;
}

.privacy-settings__setting {
  margin-bottom: 20px;
}

.privacy-settings__actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .btn {
    margin-left: 0;
    @include responsive.target(xs, sm) {
      flex: 1 1 auto;
      display: block;
      width: 100%;
    }
  }
  @include responsive.target(md, xl) {
    gap: 20px;
    flex-direction: row;
  }
}
