@use 'scss/config';
@use 'libs/styles/fonts';

.arrow-link {
  --arrow-link-arrow-translate-x: 4px;
  text-decoration: none;
  display: inline-flex;
  gap: 1ch;
  padding-right: var(--arrow-link-arrow-translate-x);
}

.arrow-link__text {
  font-size: 16px;
  line-height: 1.5;
  font-weight: fonts.$fw-light;
  color: config.$color-cod;
}

.arrow-link__arrow {
  font-family: config.$font-Icons;
  color: config.$color-curry;
  font-size: 20px;
  line-height: 1.25;
  vertical-align: middle;
  transition: transform ease-out 0.2s;

  .arrow-link:hover & {
    transform: translateX(var(--arrow-link-arrow-translate-x));
  }
}
