@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.success_story_list {
  @include responsive.target(md, xl) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.success_story_list__item {
  margin-bottom: 16px;

  .cic-overview & {
    margin-bottom: 42px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    width: 45%;

    .cic-overview & {
      margin-bottom: 0;
    }
  }

  @include responsive.target(lg, xl) {
    margin-bottom: 40px;
  }
}

.success_story_teaser {
  color: config.$color-text-black;
  text-decoration: none;

  @include responsive.target(lg, xl) {
    flex-direction: row;
    display: flex;
  }
}

.success_story_teaser__image_box {
  width: 100%;
  position: relative;
  margin-bottom: 11px;

  @include responsive.target(xs, md) {
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 0;
      padding-bottom: 98%;
    }
  }

  @include responsive.target(lg, xl) {
    width: 215px;
    margin-right: 20px;
    margin-bottom: 0;
    flex: 0 0 auto;
  }
}

.success_story_teaser__image_box_inner {
  @include responsive.target(xs, md) {
    position: absolute;
    width: 93%;
    height: 93%;
    left: 0;
    top: 0;

    &:before {
      right: -7.4626865672%;
      bottom: -7.4626865672%;
    }
  }

  @include responsive.target(lg, xl) {
    width: 200px;
    height: 267px;
    margin-bottom: 15px;

    &:before {
      right: -15px;
      bottom: -15px;
    }
  }
}

@include responsive.target(md, xl) {
  .success_story_teaser:hover .info {
    height: 100%;

    &:before {
      transform: scaleY(1);
    }
  }
}

.info__check-icon-box {
  position: absolute;
  bottom: 11px;
  left: 10px;
  width: 84px;
  height: 84px;
  line-height: 70px;
  border-radius: 50%;
  text-align: center;
  border: 6px solid config.$color-white;

  .icon {
    font-size: 50px;
  }
}

@include responsive.target(md, xl) {
  .success_story_teaser:hover .info {
    height: 100%;

    &:before {
      transform: scaleY(1);
    }
  }
}

.success_story_teaser__text-box {
  flex-grow: 1;
}

.success_story_teaser__title {
  min-height: 80px;
  font-size: 28px;
  line-height: 34px;
  font-weight: fonts.$fw-medium;
  padding-bottom: 20px;
  display: flex;
  align-items: center;

  .cic-overview & {
    font-size: 22px;
    padding-bottom: 0;
    min-height: 0;
    margin-bottom: 16px;
  }

  @include responsive.target(md, xl) {
    min-height: 90px;
    font-size: 28px;
    line-height: 1.21;

    .cic-overview & {
      font-size: 28px;
      line-height: 1.21;
    }
  }

  span {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 102px;
  }
}

.success_story_teaser__description {
  font-size: 18px;
  font-weight: fonts.$fw-light;
  line-height: 26px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: 130px;

  .cic-overview & {
    font-size: 16px;
    line-height: 1.5;
  }

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 1.44;

    .cic-overview & {
      font-size: 18px;
      line-height: 1.44;
    }
  }
}
