@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.mobile_profile_nav {
  margin-bottom: 60px;

  @include responsive.target(md, xl) {
    display: none;
  }
}

.profile_tab_wrapepr {
  width: 100%;
  overflow: auto;
  margin-bottom: 80px;
}

.profile_tab_list {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid config.$color-text-grey;

  @include responsive.target(xs, sm) {
    display: none;
  }
}

.profile_tab__link {
  text-decoration: none;
  color: inherit;
  position: relative;
}

.profile_tab {
  font-size: 22px;
  color: config.$color-black;
  letter-spacing: 0;
  line-height: 32px;
  padding: 1px 20px 0px;
  margin: 7px 0px 6px;
  border-right: 1px solid config.$color-text-grey;
  position: relative;
  white-space: nowrap;

  &::before {
    content: '';
    background-color: config.$color-straw;
    width: 100%;
    height: calc(100% + 13px);
    top: -7px;
    left: 0;
    position: absolute;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  &:hover::before {
    opacity: 1;
  }

  &--active {
    font-weight: fonts.$fw-medium;
    margin: 0;
    padding: 8px 20px 6px;
    border-top: 1px solid config.$color-text-grey;
    z-index: 10;

    &::before {
      background-color: config.$color-white;
      top: 1px;
      left: -1px;
      height: 100%;
      opacity: 1;
      border-left: 1px solid config.$color-text-grey;
      border-right: 1px solid config.$color-text-grey;
    }
  }
}
