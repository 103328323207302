@use 'libs/styles/responsive';

.login {
}
.login--invitation {
}

.login__page-title {
}

.login__title {
  .login--invitation & {
    margin-bottom: 24px;
  }

  @include responsive.target(md, xl) {
    .login--invitation & {
      margin-bottom: 40px;
    }
  }
}

.login__invitation-message {
  display: none;

  .login--invitation & {
    display: block;
    margin-bottom: 24px;
  }

  @include responsive.target(md, xl) {
    .login--invitation & {
      margin-bottom: 48px;
    }
  }
}
