@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.project_detail {
  .btn {
    align-self: flex-start;
    min-width: 100px;

    @include responsive.target(md, xl) {
      min-width: 120px;
    }
  }

  .registration {
    .btn {
      align-self: unset;
    }
  }
}

.project_detail__nav {
  width: 100%;
  display: block;
  margin: 0 0 50px;

  @include responsive.target(md, xl) {
    margin: 0 0 60px;
  }
}

.project_detail__name {
  text-align: center;
  max-width: 880px;
  margin: 0 auto 20px;

  &.h2 {
    text-align: left;
  }

  &.h3 {
    @extend .h2;
  }

  @include responsive.target(md, xl) {
    margin-bottom: 15px;

    &.h3 {
      margin-bottom: 15px;
    }
  }
}

.project_detail__date {
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
}

.project_detail__date__text {
  margin-bottom: 0;
}

.project_detail__date__icon {
  margin-right: 10px;
}

.location {
  display: flex;
  justify-content: center;
  // margin-bottom: 36px;

  @include responsive.target(md, xl) {
    margin-bottom: 20px;

    .text {
      margin-bottom: 0;
    }
  }
}

.location__icon {
  font-size: 16px;
  margin-right: 10px;

  @include responsive.target(md, xl) {
    font-size: 24px;
  }
}

.location__text {
  text-align: left !important;

  @include responsive.target(xs, sm) {
    margin-bottom: 10px;
  }

  @include responsive.target(md, xl) {
    text-align: center;
  }
}

.project_detail__image_box_wrapper {
  position: relative;

  @include responsive.target(xs, sm) {
    padding-bottom: 130.555555556%;
    margin-bottom: 20px;
  }

  @include responsive.target(md, xl) {
    width: 100%;
    max-width: 879px;
    margin: 0 auto 40px;
  }
}

.project_detail__image_box_wrapper__inner {
  @include responsive.target(md, xl) {
    padding-bottom: 63.708759954%;
    width: 100%;
  }
}

.project_detail__image_box {
  @include responsive.target(xs, sm) {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 20px);
    height: calc(100% - 20px);

    &:before {
      content: '';
      position: absolute;
      right: -20px;
      bottom: -20px;
      height: 100%;
      width: 50%;
    }
  }

  @include responsive.target(md, xl) {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 70px);
    height: calc(100% - 40px);

    &:before {
      content: '';
      position: absolute;
      right: -70px;
      bottom: -40px;
      height: 100%;
      width: 100%;
    }
  }

  &--fern {
    &:before {
      background-color: config.$color-fern;
    }
  }

  &--sand {
    &:before {
      background-color: config.$color-sand;
    }
  }

  &--terracotta {
    &:before {
      background-color: config.$color-terracotta;
    }
  }
}

.image_box__image {
  &--mobile {
    display: block;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-position: center;

    &:after {
      content: '';
      height: 130px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: rotate(-180deg);
      background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 12%,
        rgba(0, 0, 0, 0.5) 68%,
        rgba(0, 0, 0, 0.75) 100%
      );
    }
  }

  &--desktop {
    display: none;
  }

  @include responsive.target(md, xl) {
    &--mobile {
      display: none;
    }

    &--desktop {
      display: block;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
    }
  }
}

.project_detail__category {
  position: absolute;
  display: flex;
  flex-direction: column;
  // font-size: 37px;
  right: 10px;
  bottom: 10px;
  width: 36px;

  @include responsive.target(md, xl) {
    right: -50px;
    top: 60px;
  }
}

.category__text {
  display: none;

  @include responsive.target(md, xl) {
    display: block;
    font-weight: fonts.$fw-regular;
    transform: rotate(-270deg);
    font-size: 22px;
    color: config.$color-white;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 13px;
  }
}

.project_detail__description {
  .description__more {
    color: config.$color-curry;
    display: block;
  }

  .description__less {
    color: config.$color-curry;
    display: none;
  }
}

.project_detail__more {
  color: config.$color-curry;
  margin-bottom: 23px;
}

.more__link {
  font-size: 16px;
  font-weight: fonts.$fw-regular;
  text-decoration: none;
  color: config.$color-curry;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
  }
}

.project_detail__idea_giver {
  place-self: flex-start;
  color: config.$color-cod;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 80px;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
  }
}

.project_detail__heading {
  margin-bottom: 39px;
  text-align: center;
}

.project_detail__info_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include responsive.target(md, xl) {
    flex-direction: row;
  }

  .info_box__final,
  .info_box__graphic.project-progress {
    width: 236px;
    height: 236px;
    transform: none;
    color: config.$color-black;
    margin-bottom: 40px;

    @include responsive.target(md, xl) {
      order: 2;
      margin: 0 80px 30px 80px;
    }
  }

  .info_box__final__icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .icon {
      font-size: 100px;
      line-height: 100px;

      @include responsive.target(md, xl) {
        font-size: 100px;
      }
    }
  }
}

.info_box__volunteers {
  order: 1;
}

.info_box__days_until {
  order: 3;
}

.info_box__volunteers,
.info_box__days_until {
  display: flex;
  flex-direction: column;
  opacity: 1;
  align-items: center;

  .volunteers__icon {
    margin-bottom: 11px;
  }

  .days_until__icon {
    margin-bottom: 11px;
  }

  .volunteers__countdown,
  .days_until__countdown {
    color: config.$color-cod;
    font-weight: fonts.$fw-medium;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 5px;
    display: block;
    text-align: center;

    @include responsive.target(md, xl) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  .volunteers__text,
  .days_until__text {
    font-size: 16px;
    color: config.$color-black;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 37px;
    display: block;

    @include responsive.target(md, xl) {
      font-size: 18px;
      line-height: 26px;
    }
  }
}

.project_detail__text {
  margin-bottom: 58px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.project_detail__specifics {
  display: flex;
  flex-direction: column;
  place-self: baseline;

  .text {
    font-size: 16px;
    color: config.$color-cod;
    line-height: 22px;
    margin-bottom: 5px;

    @include responsive.target(md, xl) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .text--bold {
    font-weight: fonts.$fw-bold;
    font-size: 16px;
    color: config.$color-cod;
    line-height: 22px;

    @include responsive.target(md, xl) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  @include responsive.target(md, xl) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  .btn {
    margin-bottom: 22px;
    margin-left: 0;
    margin-right: 0;
    align-self: center;
  }

  @include responsive.target(xl) {
    justify-content: stretch;
    flex-direction: row;

    .btn {
      width: 100%;
      margin: 0 22px 22px;
    }

    .btn:first-child {
      margin-left: 0;
    }

    .btn:last-child {
      margin-right: 0;
    }
  }
}

.specifics__summary__label--margin-bottom {
  margin-bottom: 25px;
}

.specifics__summary {
  @include responsive.target(xs, sm) {
    margin-bottom: 25px;
  }

  @include responsive.target(md, xl) {
    padding-right: 20px;

    &:last-child {
      flex: 1 1 20%;
    }

    flex: 1 1 40%;
  }

  margin-bottom: 25px;
}

.specifics__summary,
.specifics__when,
.specifics__where,
.specifics__what {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.specifics__when,
.specifics__where,
.specifics__what {
  flex: 1 1 33.33%;
}

.specifics__link {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 40px;

  .text {
    margin: 0;
  }
}

.link__icon {
  margin-right: 10px;
}

.specifics__what {
  .text--bold {
    margin-bottom: 40px;
  }
}

.project_detail__partner {
  display: flex;
  flex-direction: column;
  max-width: 646px;
  margin: 0 auto 60px;

  @include responsive.target(md, xl) {
    margin: 0 auto 80px;
  }
}

.partner__box1 {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include responsive.target(md, xl) {
    flex-direction: row;
    justify-content: space-around;
  }
}

.partner__box2 {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include responsive.target(md, xl) {
    flex-direction: row;
    width: 100%;
  }
}

.partner__label {
  @include responsive.target(md, xl) {
    width: 50%;
    text-align: right;
    margin-right: 24px;
  }
}

.partner__name {
  @include responsive.target(md, xl) {
    margin-bottom: 20px;
    width: 50%;
    margin-left: 24px;
  }
}

.partner__logo {
  width: 100%;
  margin-bottom: 20px;
  text-align: center;

  img {
    max-width: 300px;
    max-height: 200px;
    // width: 100%;
  }

  @include responsive.target(md, xl) {
    width: 50%;
    margin-right: 24px;
    text-align: right;

    img {
      max-width: 300px;
      max-height: 300px;
      // width: 100%;
    }
  }
}

.partner__description {
  @include responsive.target(md, xl) {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: 24px;

    .text {
      margin-bottom: 10px;
    }
  }
}

.partner__link {
  display: flex;
  flex-direction: row;

  .text {
    margin-right: 5px;
    color: config.$color-curry;

    @include responsive.target(md, xl) {
      margin-right: 6px;
    }
  }
}

.project_detail__registration {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  background-color: config.$color-honey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 14px;
  z-index: 10;

  span + .btn {
    margin-top: 1.2em;
  }

  .already_registered {
    line-height: 20px;
    color: config.$color-cod;
  }
}

.registration__link {
  display: inline-block;
  min-width: 210px;
  background-color: config.$color-white;
  // margin: 14px auto;
}

.project_detail__back {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.back__link {
  display: block;
  width: 120px;

  @include responsive.target(md, xl) {
    width: 140px;
  }
}
