@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

$max-footer-content-width: 1010px;

.footer {
  background: config.$color-light-grey-background;
  padding: 60px 0 43px;
  overflow: hidden;

  @include responsive.target(md, xl) {
    padding: 100px 0 104px;
  }
}

.footer__inner {
  max-width: #{(1109px + 16px + 16px)};
  padding: 0 16px;
  margin: 0 auto;
}

.footer__main {
  max-width: $max-footer-content-width;

  @include responsive.target(md, xl) {
    display: flex;
    flex-direction: row;
  }
}

.footer__col {
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;

  @include responsive.target(md, xl) {
    &--space-between {
      justify-content: space-between;
    }

    &--mobile {
      display: none;
    }

    &:nth-child(1) {
      flex: 1 1 auto;
    }

    &:nth-child(2) {
      flex: 1 1 0;
    }

    &:nth-child(3) {
      flex: 1 1 0;
    }
  }
}

.footer__logo {
  width: 226px;
  margin-bottom: 20px;

  @include responsive.target(xs, sm) {
    align-self: center;
  }

  @include responsive.target(md, xl) {
    // remove whene more then one logo is in footer
    margin-top: 64px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.footer__title {
  font-weight: fonts.$fw-medium;
  font-size: 18px;
  color: config.$color-black;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 20px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.logo-list {
  display: flex;
  flex-direction: row;

  @include responsive.target(md, xl) {
    flex-direction: column;
  }
}

.logo-list__item {
  @include responsive.target(xs, sm) {
    height: 42px;
    margin-right: 35px;

    &:nth-child(2) {
      margin-right: 0;
    }
  }

  @include responsive.target(md, xl) {
    width: 100px;

    &:nth-child(1) {
      height: 36px;
    }

    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.footer-nav-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include responsive.target(md, xl) {
    margin-bottom: -30px;
  }
}

.footer-nav__item {
  font-weight: fonts.$fw-light;
  color: config.$color-cod;
  letter-spacing: 0;
  list-style-type: none;

  @include responsive.target(xs) {
    font-size: 12px;
    margin-bottom: 13px;
    line-height: 16px;
  }

  @include responsive.target(sm) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }

  @include responsive.target(xs, sm) {
    width: calc(50% - 8px);

    &:nth-child(2n-1) {
      margin-right: 16px;
    }
  }

  @include responsive.target(md, xl) {
    width: 33.33%;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;
  }
}

.footer-nav__link {
  color: inherit;
  text-decoration: none;
}

.mobile {
  @include responsive.target(md, xl) {
    display: none;
  }
}

.footer__bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: $max-footer-content-width;

  @include responsive.target(xs, sm) {
    display: none;
  }
}

.footer__bottom-row-column {
  font-weight: fonts.$fw-light;
  color: config.$color-cod;
  letter-spacing: 0;
  list-style-type: none;

  @include responsive.target(xs) {
    font-size: 12px;
    margin-bottom: 13px;
    line-height: 16px;
  }

  @include responsive.target(sm) {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }

  @include responsive.target(xs, sm) {
    width: calc(50% - 8px);
  }

  @include responsive.target(md, xl) {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 30px;

    .footer__bottom-row & {
      &:nth-child(1) {
        flex: 1 0 auto;
      }

      &:nth-child(3) {
        flex: 1 0 auto;
      }
    }
  }
}

.footer-social-media {
  display: flex;
  flex-direction: row;

  &.footer-social-media--mobile {
    display: flex;
    justify-content: center;
  }

  @include responsive.target(md, xl) {
    &.footer-social-media--mobile {
      display: none;
    }
  }
}

.footer-social-media__link {
  color: inherit;
  text-decoration: none;
  margin-right: 18px;
}

.share {
  color: config.$color-text-grey;
  margin-right: 23px;
}
