@use 'scss/config';
@use 'libs/styles/responsive';

.page-bar {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.filter-control {
  display: inline-flex;
  align-items: center;
  margin: 0;
  line-height: 34px;
}

.filter-control__icon {
  padding-right: 5px;
  font-size: 20px;
}

.filter-collapsible {
  display: none;
  flex-direction: column;
  border-bottom: 1px solid config.$color-stone;
  padding-top: 20px;
  padding-bottom: 50px;
  margin-bottom: 20px;

  @include responsive.target(xs, sm) {
    padding-bottom: 10px;
  }

  .project-filters--expanded & {
    display: flex;
  }
}

.filter-options {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  @include responsive.target(md, xl) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 40px;
  }
}

.filter__column {
  margin-bottom: 20px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
    flex-basis: 31%;
    max-width: 31%;
  }
}

.filter-collapsible__action-row {
  flex-direction: column;
  align-items: flex-start;

  @include responsive.target(xs, sm) {
    .btn {
      display: block;
      margin-left: 0;
    }
  }

  @include responsive.target(md, xl) {
    height: auto;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: flex-start;

    .btn {
      margin-left: 40px;
    }
  }
}

.filter-control__icon {
  &.icon-close {
    display: none;

    .project-filters--expanded & {
      display: flex;
    }
  }

  &.icon-filter {
    display: flex;

    .project-filters--expanded & {
      display: none;
    }
  }
}
