@use 'config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

body {
  font-family: config.$font-Frutiger;
}

.icon {
  font-family: config.$font-Icons;
  font-size: 18px;

  @include responsive.target(md, xl) {
    font-size: 22px;
  }

  &.icon--small {
    font-size: 18px;
  }

  &.icon--large {
    font-size: 30px;

    .icon-box--sand & {
      font-size: 22px;
    }
  }

  &.icon--medium {
    font-size: 22px;

    @include responsive.target(md, xl) {
      font-size: 40px;
    }
  }

  &.icon--x-large {
    font-size: 26px;

    @include responsive.target(md, xl) {
      font-size: 45px;
    }
  }

  &.icon--white {
    color: config.$color-white;
  }

  &.icon--curry {
    color: config.$color-curry;
  }

  &.icon--cod {
    color: config.$color-cod;
  }

  &.icon--straw {
    color: config.$color-straw;
  }

  &.icon--round {
    background: red;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 24px;
    text-align: center;

    &.icon--curry {
      background-color: config.$color-curry;
      color: config.$color-white;
    }
  }

  .btn--icon & {
    font-size: 32px;
    line-height: 28px;
  }
}

.icon-box {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;

  @include responsive.target(md, xl) {
    width: 36px;
    height: 36px;
    line-height: 36px;
  }

  &.icon-box--small {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }

  &.icon-box--medium {
    width: 48px;
    height: 48px;
    line-height: 48px;

    @include responsive.target(md, xl) {
      width: 64px;
      height: 64px;
      line-height: 64px;
    }
  }

  &.icon-box--x-large {
    width: 56px;
    height: 56px;
    line-height: 56px;

    @include responsive.target(md, xl) {
      width: 90px;
      height: 90px;
      line-height: 90px;
    }
  }

  &.icon-box--chestnut {
    background-color: config.$color-chestnut;
  }

  &.icon-box--curry {
    background-color: config.$color-curry;
  }

  &.icon-box--fern {
    background-color: config.$color-fern;
  }

  &.icon-box--pine {
    background-color: config.$color-pine;
  }

  &.icon-box--sand {
    background-color: config.$color-sand;
  }

  &.icon-box--smoke {
    background-color: config.$color-smoke;
  }

  &.icon-box--terracotta {
    background-color: config.$color-terracotta;
  }

  &.icon-box--white {
    background-color: config.$color-white;
  }
}

.richtext a,
.link {
  color: config.$color-curry;
  text-decoration: none;

  .accordion-item & {
    font-weight: fonts.$fw-regular;
  }
}

.page-title {
  display: inline-block;
  font-weight: fonts.$fw-light;
  font-size: 18px;
  color: config.$color-text-black;
  letter-spacing: 0;
  min-width: 100px;
  padding-bottom: 3px;
  margin-bottom: 20px;
  border-bottom: 2px solid config.$color-curry;

  @include responsive.target(md, xl) {
    font-size: 28px;
    line-height: 34px;
    padding-bottom: 10px;
    border-bottom: 2px solid config.$color-curry;
    min-width: 240px;
  }
}

.h1 {
  display: block;
  font-weight: fonts.$fw-medium;
  font-size: 32px;
  color: config.$color-text-black;
  letter-spacing: 0;
  line-height: 38px;
  margin-bottom: 70px;

  @include responsive.target(md, xl) {
    font-size: 64px;
    line-height: 76px;
    margin-bottom: 120px;
  }
}

.h2 {
  position: relative;
  display: block;
  font-size: 28px;
  font-weight: fonts.$fw-light;
  color: config.$color-text-black;
  line-height: 34px;
  margin-bottom: 30px;
  letter-spacing: 0;

  @include responsive.target(md, xl) {
    font-size: 48px;
    letter-spacing: 0;
    line-height: 58px;
    margin-bottom: 40px;
  }
}

.anker {
  position: absolute;
  top: -80px;
  left: 0;

  @include responsive.target(md, xl) {
    top: -130px;
  }
}

.h4 {
  position: relative;
  display: block;
  min-height: 80px;
  font-size: 24px;
  line-height: 30px;
  font-weight: fonts.$fw-medium;
  padding-bottom: 16px;
  display: flex;
  align-items: center;

  @include responsive.target(md, xl) {
    min-height: 90px;
    font-size: 28px;
    line-height: 34px;
  }
}

.richtext h3,
.richtext h5,
.h5 {
  display: block;
  font-size: 16px;
  font-weight: fonts.$fw-medium;
  color: config.$color-text-black;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 5px;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
  }
}

.richtext ul,
.richtext p,
.text {
  font-size: 16px;
  color: config.$color-cod;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 20px;
  font-weight: fonts.$fw-regular;
  // max-width: 100ch;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 27px;
  }

  &--bold {
    font-weight: fonts.$fw-bold;
    line-height: 24px;
  }

  &--link {
    color: inherit;

    .navigating-with-keyboard & {
      &:focus {
        color: config.$color-input-border-focus;
      }
    }
  }

  &--small {
    font-size: 14px;
    line-height: 22px;
  }

  &--no-margin {
    margin-bottom: 0;
  }

  &--centered {
    text-align: center;
  }

  &.text--mobile-break {
    @include responsive.target(xs, sm) {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .btn {
        margin-left: 0;
      }
    }
  }
}

.richtext strong {
  font-weight: fonts.$fw-bold;
  font-size: 16px;
  color: config.$color-cod;
  line-height: 22px;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
  }
}

.richtext li,
.bullet {
  color: config.$color-cod;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 5px;
  margin-left: 30px;
  list-style-type: disc;
  max-width: 100ch;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 4px;
  }

  &--small {
    font-size: 14px;
    line-height: 18px;

    @include responsive.target(md, xl) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.lead-text {
  font-size: 16px;
  color: config.$color-cod;
  line-height: 24px;
  margin-bottom: 20px;
  max-width: 100ch;

  &--bold {
    font-weight: fonts.$fw-bold;
  }

  &--margin-bottom {
    margin-bottom: 55px;
  }

  @include responsive.target(md, xl) {
    font-size: 22px;
    line-height: 32px;
  }
}

.lead-title {
  font-size: 28px;
  line-height: 34px;
  font-weight: 300;
  margin-bottom: 30px;

  @include responsive.target(md, xl) {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 40px;
  }
}

.subtitles {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;

  @include responsive.target(md, xl) {
    font-size: 28px;
    line-height: 34px;
  }
}

.indent {
  margin-left: 20px;
  line-height: 20px;
  margin-bottom: 0;

  @include responsive.target(md, xl) {
    margin-left: 30px;
    line-height: 27px;
    margin-bottom: 0;
  }
}

.separator {
  &:not(&--no-line) {
    border: 1px solid config.$color-silver;
  }

  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
}

.separator__text {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: config.$color-white;
  padding: 5px 20px;
}

.align-center {
  text-align: center;
}

.align-right {
  display: flex;
  justify-content: flex-end;
}

.toc {
  margin-bottom: 50px;

  @include responsive.target(md, xl) {
    margin-bottom: 100px;
  }
}

.toc__item {
  font-weight: fonts.$fw-regular;
  font-size: 16px;
  line-height: 28px;
  color: config.$color-curry;
  list-style-type: disc;
  margin-left: 20px;

  @include responsive.target(md, xl) {
    font-size: 22px;
    line-height: 36px;
  }
}

.not-published {
  font-size: 14px;
  color: config.$color-form-error-text;
  line-height: 16px;
  background: config.$color-form-error-bg;
  padding: 5px 10px;
  text-align: center;
}

@include responsive.target(xs, sm) {
  .button-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn,
    .btn + .btn {
      margin: 0 0 15px;
    }
  }
}
