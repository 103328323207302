@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.main-nav {
  position: fixed;
  z-index: config.$z-index-main-nav;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: #{(1195px + 16px + 16px)};
  transform: translateX(-50%);
  height: 0;
}

.main-nav__opener {
  position: absolute;
  right: 10px;
  top: #{config.$header-height--mobile + 10px};
  cursor: pointer;
  background: config.$color-mercury;
  border-radius: 4px;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 7px;

  @include responsive.target(lg, xl) {
    top: #{config.$header-height--desktop + 10px};
    right: 16px;
    padding: 8px 10px;
  }

  @include responsive.target(xs, md) {
    @include helpers.headerTransition;

    .header--small ~ .main-nav & {
      transform: translateY(-30px);
    }
  }

  @media (max-width: 350px) {
    transform: translateY(15px);

    .header--small ~ .main-nav & {
      transform: translateY(-15px);
    }
  }
}

.nav-opener__text {
  margin-right: 8px;
  font-size: 18px;
  color: config.$color-cod;
  letter-spacing: -0.2px;

  @include responsive.target(xs, md) {
    @include helpers.visually-hidden;
  }
}

.nav-opener__lines {
  width: 22px;
  height: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include responsive.target(lg, xl) {
    height: 20px;
  }
}

.nav-opener__line {
  display: block;
  height: 2px;
  background: config.$color-cod;

  @include responsive.target(xs, md) {
    &:last-child {
      display: none;
    }
  }
}

.main-nav__inner {
  position: absolute;

  @include responsive.target(xs, md) {
    top: 50px;
    width: 100%;
    left: 0;
    height: calc(100vh - 50px);
    overflow: auto;
    background: config.$color-honey;
    transform: translateX(100%);
    transition-property: transform;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    visibility: hidden;

    .main-nav--open & {
      visibility: visible;
      transform: translateX(0);
    }

    .navigating-with-keyboard & {
      transition: none;
    }
  }

  @include responsive.target(lg, xl) {
    top: #{config.$header-height--desktop + 10px};
    width: 274px;
    right: 16px;
    height: 296px;
    transform-origin: 100% 0;
    transform: scaleY(1) scaleX(1);
    opacity: 1;
    transition-property: transform, opacity;
    transition-duration: 300ms, 100ms;
    transition-delay: 0ms, 0ms;
    transition-timing-function: ease-in-out;
    visibility: visible;

    .main-nav--collapsed:not(:hover) & {
      transform: scaleY(0.1) scaleX(0.3);
      transition-delay: 0ms, 200ms;
      opacity: 0;
      visibility: hidden;
    }

    .navigating-with-keyboard & {
      transition: none;
    }
  }
}

.main-nav__scroller {
  @include responsive.target(xs, md) {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.main-nav__closer {
  width: 22px;
  height: 22px;
  overflow: hidden;
  position: absolute;
  left: 10px;
  top: 20px;
  color: config.$color-cod;
  background-color: config.$color-honey;
  font-size: 22px;
  border: none;
  outline: none;
  padding: 0;
  cursor: pointer;

  @include responsive.target(lg, xl) {
    display: none;
  }
}

.nav-list {
  @include responsive.target(xs, md) {
    padding: 50px 20px 40px 40px;
  }
}

.nav-list__item {
  font-weight: fonts.$fw-medium;
  font-size: 28px;
  color: config.$color-cod;
  letter-spacing: 0;
  line-height: 34px;
  margin-bottom: 15px;

  &--active {
    color: rgba(config.$color-cod, 0.5);
  }

  @include responsive.target(lg, xl) {
    font-size: 22px;
    line-height: 32px;
    background-color: config.$color-honey;
    margin-bottom: 4px;
    transition: background-color 250ms ease-in-out;

    &:hover {
      background-color: config.$color-curry;
    }
  }
}

.nav-list__small-label {
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.4em;
  display: block;
}

.nav-list__link {
  color: inherit;
  display: block;
  text-decoration: none;

  @include responsive.target(lg, xl) {
    padding: 7px 20px;
  }
}

.nav-meta {
  @include responsive.target(xs, md) {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 34px 16px 20px;
    height: 202px;
    background: config.$color-nav-meta-bg;
  }

  @include responsive.target(lg, xl) {
    display: none;
  }
}

.nav-meta__logo {
  position: absolute;
  bottom: 51px;
  left: -40px;
  transform: rotate(45deg);
}

.nav-meta__link {
  font-size: 14px;
  color: config.$color-cod;
  text-align: right;
  line-height: 18px;
  margin-bottom: 14px;
  font-weight: fonts.$fw-light;
}

.nav-meta__copyright {
  display: block;
  width: 100%;
  font-size: 14px;
  font-weight: fonts.$fw-light;
  color: config.$color-text-footer-contrast;
  letter-spacing: 0;
  line-height: 18px;
}
