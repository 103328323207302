@use 'scss/config';
@use 'libs/styles/responsive';

.social-media-sharing {
  display: flex;
  gap: 10px;
  justify-content: center;

  &.social-media-sharing--align-left {
    justify-content: flex-start;
  }

  .table-list & {
    justify-content: space-between;
  }

  .project-overview-teaser & {
    justify-content: space-between;
  }

  @include responsive.target(md, xl) {
    gap: 16px;

    .table-list & {
      justify-content: flex-start;
    }

    .project-overview-teaser & {
      justify-content: flex-start;
    }
  }
}

.social-media-sharing__link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: config.$color-curry;
}
