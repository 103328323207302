@use 'libs/styles/responsive';


.company-overview__intro-text {
  margin-bottom: 40px;
}

.company-overview__ctas {
  margin-bottom: 24px;

  @include responsive.target(md, xl) {
    margin-bottom: 64px;
  }
}

.company-overview__intro {
  margin-bottom: 16px;

  @include responsive.target(lg, xl) {
    margin-bottom: 24px;

    &.company-overview__intro--no-description {
      grid-template-columns: auto;
    }
  }
}

.company-overview__intro-col {
  margin-bottom: 16px;

  @include responsive.target(lg, xl) {
    margin-bottom: 24px;
  }
}

.company-overview__intro-col--left {
  order: 1;
}

.company-overview__intro-col--right {
  order: 2;
}

.company-overview__logo {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  img {
    display: block;
    max-height: 66px;
  }
}
