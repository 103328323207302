@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.banner {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 15px 12px;
  align-items: flex-start;
  gap: 12px;
  @include responsive.target(md, xl) {
    border-radius: 10px;
    padding: 20px 25px;
    gap: 25px;
  }
  &.banner--warning {
    background: rgba(config.$color-chestnut, 0.1);
  }
  &.banner--info {
    background: rgba(3, 93, 172, 0.1);
  }
}

.banner__icon {
  flex: 0 0 auto;
  width: 18px;
  height: 18px;
  svg {
    width: 100%;
    height: 100%;
  }
  @include responsive.target(md, xl) {
    width: 30px;
    height: 30px;
  }
}

.banner__content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-self: center;
}

.banner__text {
  color: config.$color-black;
  font-size: 10px;
  font-style: normal;
  font-weight: fonts.$fw-regular;
  line-height: 17px; /* 142.857% */
  @include responsive.target(md, xl) {
    font-size: 14px;
    line-height: 20px; /* 142.857% */
  }
  b {
    font-weight: fonts.$fw-bold;
  }
}
