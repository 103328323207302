@use 'scss/config';
@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.logo-uploader {
}

.logo-uploader__form {
}

.logo-uploader__main {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include responsive.target(md, xl) {
    flex-direction: row;
    align-items: flex-end;
  }
}

.logo-uploader__left {
}

.logo-uploader-dropzone {
  display: block;
  position: relative;
  width: 100%;
  aspect-ratio: 1.93;
  border: 1px dashed config.$color-input-border-hover;
  background-color: config.$color-alabaster;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 82px;

  @include responsive.target(md, xl) {
    aspect-ratio: 1.46;
    width: 345px;
    flex-shrink: 0;
  }
}

.logo-uploader-dropzone__preview {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  pointer-events: none;
  user-select: none;

  &[src=''] {
    opacity: 0;
  }
}

.logo-uploader-dropzone__button {
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
}

.logo-uploader-dropzone__input {
  @include helpers.visually-hidden;
}

.logo-uploader__right {
}

.logo-uploader__checkbox {
  margin-bottom: 32px;
}

.logo-uploader__last-updated {
  font-size: 16px;
  line-height: 1.5;
  color: config.$color-smoke;
  margin-bottom: 16px;
}

.logo-uploader__errors {
  margin-top: 16px;
}
