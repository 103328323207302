@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.survey-title {
  font-size: 18px;
  color: config.$color-black;
  font-weight: fonts.$fw-medium;
  letter-spacing: 0;
  margin-bottom: 20px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
    font-size: 28px;
    line-height: 34px;
  }
}
