@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.wide-visual-teaser {
  width: 100%;

  @include responsive.target(md, xl) {
    display: flex;
    flex-direction: row-reverse;
    min-height: 260px;
  }
}

.wide-visual-teaser__visual {
  height: 214px;
  width: 100%;
  background-color: config.$color-mercury;

  @include responsive.target(md, xl) {
    width: 50%;
    height: auto;
    flex-shrink: 0;
  }
}

.wide-visual-teaser-image {
  object-fit: cover;
  object-position: top center;
  width: 100%;
  height: 100%;
}

.wide-visual-teaser__text {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: config.$color-honey;
  padding-top: 32px;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 32px;

  @include responsive.target(md, xl) {
    justify-content: center;
  }

  @include responsive.target(lg, xl) {
    padding-top: 60px;
    padding-left: 32px;
    padding-right: 43px;
    padding-bottom: 60px;
    flex-shrink: 0;
    width: 50%;
  }
}

.wide-visual-teaser__title {
  font-size: 28px;
  font-weight: fonts.$fw-light;
  line-height: 1.21;
  color: config.$color-cod;
  text-align: center;
  margin-bottom: 24px;

  @include responsive.target(md, xl) {
    margin-bottom: 32px;
  }
}

.wide-visual-teaser__button {
  display: inline-block;
  margin: 0 auto;
  color: config.$color-curry;
  font-size: 16px;
  font-weight: fonts.$fw-medium;
  line-height: 1.5;
  padding-top: 8px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 8px;
  background-color: config.$color-white;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color ease-in-out 0.3s, color ease-in-out 0.3s;

  &:hover {
    background-color: config.$color-curry;
    color: config.$color-white;
  }
}
