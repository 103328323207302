@use 'sass:math';
@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.header-spacer {
  height: config.$header-height--mobile;

  @include responsive.target(lg, xl) {
    height: config.$header-height--desktop;
  }
}

.header {
  position: fixed;
  display: block;
  width: 100%;
  z-index: config.$z-index-header;
  background-color: config.$color-white;
  border-bottom: 1px solid config.$color-silver;
  box-shadow: 0 2px 15px 0 rgba(config.$color-black, 0.11);
  overflow: hidden;

  @include responsive.target(xs, md) {
    @include helpers.headerTransition;

    &--small {
      transform: translateY(-30px);
    }
  }
}

.header__inner {
  padding: 12px 16px 7px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;

  @include responsive.target(xs, md) {
    @include helpers.headerTransition;

    .header--small & {
      transform: translateY(30px);
    }
  }

  @include responsive.target(lg, xl) {
    max-width: #{(1195px + 16px + 16px)};
    padding: 26px 16px 18px;
  }
}

.logo {
  @include responsive.target(lg, xl) {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
}

.logo__svg {
  display: block;
  height: 42px;
  width: 150px;
  @include helpers.headerTransition;
  transform-origin: 0 0;

  @include responsive.target(xs, md) {
    margin-bottom: 5px;

    .header--small & {
      transform: scale(#{(math.div(30px, 42px))});
    }
  }

  @include responsive.target(lg, xl) {
    height: 55px;
    width: 194px;
  }
}

.logo-text {
  fill: config.$color-black;
  fill: windowText;
}

.logo__claim {
  display: block;
  font-weight: fonts.$fw-light;
  font-size: 10px;
  color: config.$color-text-black;
  letter-spacing: 0.2px;
  margin-left: 38px;
  line-height: 13px;
  transform-origin: 0 0;

  @include responsive.target(xs, md) {
    opacity: 1;
    @include helpers.headerTransition;
    transition-property: transform, opacity;

    .header--small & {
      transform: scaleY(0);
      opacity: 0;
    }
  }

  @include responsive.target(lg, xl) {
    font-size: 22px;
    color: config.$color-text-2;
    letter-spacing: 0;
    text-align: left;
    line-height: 32px;
    margin-left: 46px;
  }
}

.ubs_logo {
  padding-top: 4px;

  @include responsive.target(lg, xl) {
    padding-top: 9px;
  }
}

.ubs_logo__svg {
  width: 60px;
  height: 22px;

  @include responsive.target(lg, xl) {
    height: 40px;
    width: 107px;
  }
}

.ubs-logo-key {
  fill: config.$color-black;
  fill: windowText;
}

.header_close {
  @include responsive.target(lg, xl) {
    align-self: center;
  }
}

.header_close__link {
  display: inline-block;
  text-decoration: none;
  font-size: 30px;
  color: inherit;
}
