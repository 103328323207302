@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.project_category__name {
  @include responsive.target(lg, xl) {
  }
}

.project_category__header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .h2 {
    margin-bottom: 0;
  }

  @include responsive.target(xs, sm) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.project_category__btn {
  display: block;
  margin: 0 auto;

  @include responsive.target(md, xl) {
    display: none;
  }
}

.display_all {
  display: none;
  color: config.$color-curry;
  white-space: nowrap;

  @include responsive.target(md, xl) {
    display: block;
  }
}

.display_all__icon {
  font-size: 25px;
}

.display_all__link {
  font-size: 22px;
  font-weight: fonts.$fw-medium;
  text-decoration: none;
  color: config.$color-curry;
}

.load_more_projects {
  display: none;

  &--show {
    display: block;
  }
}

.project_list_actions {
  display: flex;

  @include responsive.target(xs, sm) {
    flex-direction: column-reverse;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;

    .btn {
      margin-bottom: 20px;
      margin-left: 0;
      width: 100%;
    }
  }

  @include responsive.target(md, xl) {
    flex-direction: row;
    justify-content: space-between;

    .action-spacer {
      width: 200px;
    }
  }
}
