@use 'libs/styles/responsive';

.privacy-overlay {
  .text:last-child {
    margin-bottom: 0;
  }
}

.privacy-overlay__actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .btn {
    margin-left: 0;
    @include responsive.target(xs, sm) {
      flex: 1 1 auto;
      display: block;
      width: 100%;
    }
  }
  @include responsive.target(md, xl) {
    gap: 20px;
    flex-direction: row;
  }
}

.privacy-overlay__benefits {
  margin-bottom: 20px;
  @include responsive.target(md, xl) {
    margin-bottom: 27px;
  }
}

.privacy-overlay__actions {
  margin-bottom: 10px;
  @include responsive.target(md, xl) {
    margin-bottom: 13px;
  }
}
