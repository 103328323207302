@use 'scss/config';

.employees-list {
  width: 100%;

  tr {
    display: block;
  }
}

.employee {
  margin-bottom: 8px;
}

.employee__name {
  display: flex;
  align-items: center;
  width: 50%;
}

.employee__timing {
  width: 20%;
}

.employee__cdate {
  width: 20%;
}

.employee__arrow {
  width: 10%;
  text-align: right;
}

.employee--blocked .employee_link {
  background-color: #cc9e9b;
}

.employee--deleted .employee_link {
  background-color: #e6e6e6;
}
