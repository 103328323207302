@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.file-field__input {
  @include helpers.visually-hidden;
}

.file-field__input:focus + .file-field__label,
.file-field--dragover .file-field__label,
.file-field__input + .file-field__label:hover {
  background-color: #f5f5f5;
}

.file-field__input:focus + .file-field__label {
  outline: none;
}

.file-field__label {
  color: config.$primary-color;
  background-color: config.$color-white;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  width: 100%;
  padding: 7px 5px 6px;
  border: 2px solid config.$primary-color;
  border-radius: 4px;

  @include responsive.target(md, xl) {
    border: 1px dashed config.$color-stone;
    padding: 16px 56px;
    font-size: 14px;
    font-weight: fonts.$fw-regular;
    color: config.$color-carbon;
    text-align: left;
  }

  .file-field--has-file & {
    display: none;
  }
}

.file-field__label__icon {
  display: inline-block;
  font-family: config.$font-Icons;
  font-size: 24px;
  height: 80px;
  width: 80px;
  border-radius: 40px;
  background-color: #fafafa;
  text-align: center;
  line-height: 80px;
  margin-right: 16px;

  @include responsive.target(xs, sm) {
    display: none;
  }
}

.file-field__file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 2px solid config.$color-silver;
  padding-bottom: 5px;
  width: fit-content;
}

.file-field__file__name {
  flex: 1;
  word-break: break-all;
}

.file-field__file__size {
  margin: 0 16px;
}

.file-field__file__delete {
  background-color: config.$color-chestnut;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;

  &:before {
    font-family: config.$font-Icons;
    font-size: 20px;
    color: config.$color-white;
    content: '\e909';
  }
}
