@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

button {
  color: config.$color-black;
}

.btn {
  display: inline-block;
  background: none;
  border: 2px solid transparent;
  outline: none;
  text-decoration: none;
  font-family: config.$font-Frutiger;
  font-weight: fonts.$fw-medium;
  cursor: pointer;
  color: config.$color-cod;
  letter-spacing: 0;
  text-align: center;
  padding: 7px 6px;
  width: max-content;
  font-size: 16px;
  line-height: 20px;
  border-radius: 4px;
  transition-property: background-color, color, border-color;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;

  & + .btn {
    margin-left: 40px;
  }

  @include responsive.target(md, xl) {
    font-size: 18px;
    padding: 9px 10px;
    line-height: 22px;
  }

  @media (hover: hover),
    screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    &:hover {
      background-color: config.$color-cod;
      color: config.$color-white;

      &.btn--primary {
        background-color: config.$color-curry;
      }

      &.btn--secondary {
        background-color: config.$color-carbon;
      }

      &.btn--danger {
        background-color: config.$color-form-error-text;
      }
    }
  }
}

.btn[disabled] {
  border-color: config.$color-mercury;
  background-color: config.$color-mercury;
  color: config.$color-smoke;
  cursor: default;
}

.btn--full {
  width: 100%;
}

.btn--wide {
  min-width: 120px;

  @include responsive.target(md, xl) {
    min-width: 210px;
  }
}

.btn--small {
  font-size: 16px;
  padding: 8px 8px;
}

.btn--small-mobile {
  @include responsive.target(xs, sm) {
    font-size: 16px;
    padding: 7px 8px 6px;
  }
}

.btn--primary {
  color: config.$color-curry;
}

.btn--secondary {
  color: config.$color-carbon;
}

.btn--danger {
  color: config.$color-form-error-text;
}

.btn--white {
  background-color: config.$color-white;

  .message-box & {
    padding-left: 24px;
    padding-right: 24px;
    color: config.$color-cod;

    &.btn--primary {
      color: config.$color-sand;
    }
  }

  .box-cta & {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (hover: hover),
    screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    &:hover {
      background-color: config.$color-curry;
      color: config.$color-white;

      &.btn--outline {
        border-color: config.$color-curry;
      }

      .message-box & {
        color: config.$color-cod;
        background-color: config.$color-background;
      }
    }
  }
}

.btn--outline {
  border: 2px solid config.$color-cod;

  &.btn--primary {
    border: 2px solid config.$color-curry;
  }

  &.btn--white {
    border: 2px solid config.$color-white;
  }

  &.btn--secondary {
    border: 2px solid config.$color-carbon;
  }

  &.btn--danger {
    border: 2px solid config.$color-form-error-text;
  }
}

.btn--filled {
  padding: 9px 20px;
  background-color: config.$color-silver;

  @media (hover: hover),
    screen and (-ms-high-contrast: active),
    (-ms-high-contrast: none) {
    &:hover {
      background-color: config.$color-carbon;
      color: config.$color-white;
    }
  }

  &.btn--primary {
    color: config.$color-white;
    background-color: config.$color-curry;
  }
}

.btn--align-left {
  text-align: left;
}

.btn--icon {
  padding-top: 6px;
  padding-bottom: 6px;
  display: inline-flex;
  align-items: center;
}

.btn--with-icon {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  line-height: 1;
}

.buttons-row--stack-on-xs {
  .btn {
    display: block;
    margin-bottom: 1rem;

    & + .btn {
      margin-left: 0;
    }
  }

  @include responsive.target(md, xl) {
    .btn {
      display: inline-block;

      & + .btn {
        margin-left: 40px;
      }
    }
  }
}

.btn-row {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @include responsive.target(xs, sm) {
    .btn {
      flex: 1 1 auto;
      width: 100%;
    }
  }
  @include responsive.target(md, xl) {
    flex-direction: row;
  }
  .btn + .btn {
    margin-left: 0;
  }
}
