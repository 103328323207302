@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.project-accordion {
  border-bottom: 1px solid config.$color-mercury;
  margin-bottom: 20px;
  padding-bottom: 20px;
  transition: padding 0.3s ease-in-out;
  &.project-accordion--open {
    padding-bottom: 0;
  }
  @include responsive.target(md, xl) {
    margin-bottom: 45px;
    padding-bottom: 45px;
    &.project-accordion--open {
      padding-bottom: 0;
    }
  }
}

.project-accordion__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: config.$color-black;
  font-size: 24px;
  font-style: normal;
  font-weight: fonts.$fw-light;
  line-height: 23px;
  cursor: pointer;
  @include responsive.target(md, xl) {
    font-size: 48px;
    line-height: normal;
  }
}

.project-accordion__header-icon {
  font-family: config.$font-Icons;
  font-weight: fonts.$fw-regular;
  transition: transform 0.3s ease-in-out;
  user-select: none;

  .project-accordion--open & {
    transform: rotate(90deg);
  }
}

.project-accordion__content {
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  @include responsive.target(md, xl) {
    margin-left: -25px;
    margin-right: -25px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

.project-accordion__content-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  @include responsive.target(md, xl) {
    gap: 45px;
    padding-top: 45px;
    padding-bottom: 45px;
  }
}
