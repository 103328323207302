@use 'scss/config';
// @use 'libs/styles/helpers';
// @use 'libs/styles/responsive';

.bottom-sheet {
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  overflow: hidden;
}

.bottom-sheet--open {
  pointer-events: initial;
  opacity: 1;
}

.bottom-sheet__close {
  font-family: config.$font-Icons;
  position: absolute;
  top: 4px;
  right: 10px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 2px solid transparent;
  outline: none;
  cursor: pointer;
}

.bottom-sheet__inner {
  display: flex;
  flex-direction: column;
  gap: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 80vh;
  width: 100%;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  transition: transform 0.6s ease-in-out;
  .bottom-sheet--open & {
    // transition: transform 0.9s ease-in-out;
    transform: translateY(1px);
  }
}

.bottom-sheet__content {
  overflow: auto;
  flex: 1 1 auto;
}

.bottom-sheet__header {
  min-height: 20px;
}
