@use 'scss/config';
@use 'libs/styles/responsive';

.swipe_wrapper {
  @include responsive.target(xs, sm) {
    margin-bottom: 80px;
  }
}

.swipe_container {
  @include responsive.target(xs, sm) {
    position: relative;
    width: 100%;
    overflow: hidden;

    .project_list {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: 400%;
    }

    .project_list__item {
      max-width: none;
      width: 25%;
    }

    &[data-active='0'] {
      .project_list {
        transform: translateX(0);
        transition-property: transform;
        transition-duration: 500ms;
        transition-timing-function: ease-in-out;
      }
    }

    &[data-active='1'] {
      .project_list {
        transform: translateX(-25%);
        transition-property: transform;
        transition-duration: 500ms;
        transition-timing-function: ease-in-out;
      }
    }

    &[data-active='2'] {
      .project_list {
        transform: translateX(-50%);
        transition-property: transform;
        transition-duration: 500ms;
        transition-timing-function: ease-in-out;
      }
    }

    .project_teaser {
      margin-bottom: 30px;
    }
  }
}

.dot_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  @include responsive.target(md, xl) {
    display: none;
  }
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #d7d7d7;
  margin-right: 16px;

  &:last-child {
    margin-right: 0;
  }

  &--active {
    background-color: #444444;
  }
}
