@use 'scss/config';
@use 'libs/styles/responsive';

.hero {
  position: relative;
  max-width: 2560px;
  margin: 0 auto;
  height: 417px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  @include responsive.target(md, xl) {
    height: calc(100vh - 410px);
    align-items: flex-end;
    justify-content: flex-start;

    @media screen and (max-height: 1000px) {
      height: calc(100vh - 310px);
    }
  }
}

.hero__background-slider {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;

  &[data-active='0'] {
    .hero__background:nth-child(1) {
      transform: translateX(0);
    }

    .hero__background:nth-child(2) {
      transform: translateX(0);
      opacity: 0;
    }

    .hero__background:nth-child(3) {
      transform: translateX(-300%);
    }
  }

  &[data-active='1'] {
    .hero__background:nth-child(1) {
      transform: translateX(-100%);
    }

    .hero__background:nth-child(2) {
      transform: translateX(-100%);
    }

    .hero__background:nth-child(3) {
      transform: translateX(-100%);
      opacity: 0;
    }
  }

  &[data-active='2'] {
    .hero__background:nth-child(1) {
      transform: translateX(100%);
      opacity: 0;
    }

    .hero__background:nth-child(2) {
      transform: translateX(-200%);
    }

    .hero__background:nth-child(3) {
      transform: translateX(-200%);
    }
  }
}

.hero__background {
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  transition: transform 1000ms ease-in-out;
  background-size: cover, auto, cover;
  background-repeat: no-repeat;
  background-position-x: 70%, center, 70%;
  background-position-y: 0, bottom, 0;
  background-blend-mode: soft-light;

  @include responsive.target(md, xl) {
    background-size: cover, auto, cover;
    background-position-x: 70%, center, center;
    background-position-y: 0, bottom, center;
  }

  &:nth-child(1) {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.5)
      ),
      url('/static/images/hero-logo-mobile.svg'),
      url('/static/images/ubs-helpetica.ch-Header-Sujet1-2560x1689_Health.jpg');

    @include responsive.target(md, xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.5)
        ),
        url('/static/images/hero-logo-desktop.svg'),
        url('/static/images/ubs-helpetica.ch-Header-Sujet1-2560x1689_Health.jpg');
    }
  }

  &:nth-child(2) {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.5)
      ),
      url('/static/images/hero-logo-mobile.svg'),
      url('/static/images/ubs-helpetica.ch-Header-Sujet4-2560x1689_Environment.jpg');

    @include responsive.target(md, xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.5)
        ),
        url('/static/images/hero-logo-desktop.svg'),
        url('/static/images/ubs-helpetica.ch-Header-Sujet4-2560x1689_Environment.jpg');
    }
  }

  &:nth-child(3) {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.5)
      ),
      url('/static/images/hero-logo-mobile.svg'),
      url('/static/images/ubs-helpetica.ch-Header-Sujet1-2560x1689_Nachhaltigkeit.jpg');

    @include responsive.target(md, xl) {
      background-image: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.5)
        ),
        url('/static/images/hero-logo-desktop.svg'),
        url('/static/images/ubs-helpetica.ch-Header-Sujet1-2560x1689_Nachhaltigkeit.jpg');
    }
  }

  // &:nth-child(4) {
  //   background-image: linear-gradient(
  //       to bottom,
  //       rgba(0, 0, 0, 0),
  //       rgba(0, 0, 0, 0),
  //       rgba(0, 0, 0, 0.5)
  //     ),
  //     url('/static/images/hero-logo-mobile.svg'),
  //     url('/static/images/unternehmertum-mobile.jpg');

  //   @include responsive.target(md, xl) {
  //     background-image: linear-gradient(
  //         to bottom,
  //         rgba(0, 0, 0, 0),
  //         rgba(0, 0, 0, 0),
  //         rgba(0, 0, 0, 0.5)
  //       ),
  //       url('/static/images/hero-logo-desktop.svg'),
  //       url('/static/images/unternehmertum-desktop.jpg');
  //   }
  // }
}

.hero__title {
  color: config.$color-white;
  font-size: 32px;
  line-height: 39px;
  font-weight: 500;
  margin-right: 100px;
  margin-bottom: 20px;
  width: 12ch;
  opacity: 0;
  animation-name: herotitle;
  animation-duration: 0.8s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  overflow: hidden;
  z-index: 10;
  text-shadow: 0 0 10px config.$color-black;

  @media (prefers-reduced-motion: reduce) {
    opacity: 1;
  }

  @include responsive.target(md, xl) {
    font-size: 64px;
    line-height: 76px;
    width: 100%;
    margin-bottom: 13vh;
    max-width: 1227px;
    padding: 0 16px;
    margin-left: auto;
    margin-right: auto;
  }
}

@keyframes herotitle {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero__control {
  outline: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  left: 16px;
  bottom: 16px;
  height: 32px;
  width: 24px;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    forced-color-adjust: none;
  }

  &::before {
    left: 0;
    border-left: 24px solid config.$color-white;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
  }

  &::after {
    opacity: 0;
    width: 8px;
    right: 0;
    height: 100%;
    background-color: config.$color-white;
  }

  .hero--playing & {
    &::before {
      border: 0;
      height: 100%;
      width: 8px;
      background-color: config.$color-white;
    }

    &::after {
      opacity: 1;
    }
  }
}
