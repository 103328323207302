@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.breadcrumbs {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.breadcrumbs__crumb {
}

.breadcrumbs__text {
  font-size: 16px;
  line-height: 1.5;
  color: config.$color-cod;
  text-decoration: none;
  font-weight: fonts.$fw-light;
  display: flex;
  align-items: center;

  &::after {
    content: '';
    display: block;
    margin-left: 12px;
    margin-right: 12px;
    height: 15px;
    width: 8px;
    background-image: url("data:image/svg+xml;utf8,%3Csvg width='10' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.35595 8L0.919945 14.44L1.98595 15.5L8.41895 9.063L9.48095 8L8.41895 6.937L1.98595 0.5L0.918945 1.559L7.35595 8Z' fill='%231C1C1C'/%3E%3C/svg%3E%0A");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 2px;
  }

  .breadcrumbs__crumb:last-child & {
    font-weight: fonts.$fw-medium;

    &::after {
      content: none;
    }
  }

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 1.44;
  }
}
