@use 'scss/config';
@use 'libs/styles/responsive';

.important_note {
  background: config.$color-chestnut;
  color: config.$color-white;
  padding: 10px;

  .project_detail .project_teaser &,
  .project_teaser & {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
  }

  .project_detail & {
    max-width: 220px;
    position: absolute;
    left: 0;
    top: 36px;

    @include responsive.target(md, xl) {
      padding: 20px;
      max-width: 320px;
    }

    @include responsive.target(lg, xl) {
      left: -45px;
    }
  }

  .project-volunteer-teaser & {
    margin-bottom: 20px;
    max-width: 1120px;
  }
}

.important_note__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.important_note__icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  margin-right: 5px;
}

.important_note__text {
  font-size: 16px;
  line-height: 22px;

  .project_detail & {
    @include responsive.target(md, xl) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  .project_detail .project_teaser & {
    font-size: 16px;
    line-height: 22px;
  }
}
