@use 'scss/config';
@use 'libs/styles/responsive';

.yes-no-modal {
  position: static;
  border: 0;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  background-color: config.$color-white;
  border-radius: 4px;
  display: flex;
  gap: 16px;
  margin: 16px;

  @include responsive.target(md, xl) {
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 32px;
    margin: 32px;
    gap: 24px;
  }
}

.yes-no-modal__icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: config.$color-curry;
  color: config.$color-white;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    font-size: 16px;
  }

  @include responsive.target(md, xl) {
    width: 40px;
    height: 40px;

    .icon {
      font-size: 20px;
    }
  }
}

.yes-no-modal__main {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include responsive.target(md, xl) {
    gap: 32px;
  }
}

.yes-no-modal__content {
  > :last-child {
    margin-bottom: 0;
  }
}

.yes-no-modal__actions {
  display: flex;
  gap: 8px;
}
