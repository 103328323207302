@use 'scss/config';
@use 'libs/styles/responsive';

#overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow: auto;
  z-index: config.$z-index-overlay;

  .overlay-shadow {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(config.$color-cod, 0.5);
  }

  .overlay {
    position: absolute;
    top: 11vh;
    left: 50%;
    transform: translateX(-50%);
    background-color: config.$color-white;
    width: 879px;
    max-width: 90vw;
    padding: 60px 20px;

    @include responsive.target(md, xl) {
      padding: 80px 74px;
    }

    &__closer {
      position: absolute;
      width: 24px;
      height: 24px;
      top: 20px;
      right: 20px;
      border: none;
      background: transparent;
      color: config.$color-black;
      padding: 0;
      font-size: 32px;
      cursor: pointer;

      &:focus {
        outline: none;
      }

      @include responsive.target(lg, xl) {
        top: 40px;
        right: 40px;
      }
    }
    &.overlay--disable-closing {
      .overlay__closer {
        display: none;
      }
    }
  }
}
