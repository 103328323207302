@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.registration-guard {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.registration-guard__page-title {
  @include responsive.target(md, xl) {
    margin-bottom: 32px;
  }
}

.registration-guard__invitation-message {
  margin-bottom: 24px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.registration-guard__title {
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 24px;

  @include responsive.target(md, xl) {
    font-size: 28px;
    margin-bottom: 40px;
  }
}

.registration-guard__message-box {
  margin-bottom: 32px;

  @include responsive.target(md, xl) {
    margin-bottom: 48px;
  }
}

.registration-guard__columns {
  @include responsive.target(md, xl) {
    display: flex;
    gap: 42px;
  }
}

.registration-guard__column {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }

  @include responsive.target(md, xl) {
    flex-shrink: 1;
    flex-grow: 1;
    flex-basis: 0;
  }
}

.registration-guard__button {
  display: block;
  margin-bottom: 8px;

  @include responsive.target(md, xl) {
    margin-bottom: 16px;
  }
}

.registration-guard__text {
  font-size: 16px;
  line-height: 1.5;
  color: config.$color-cod;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 1.44;
  }
}
