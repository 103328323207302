@use 'libs/styles/responsive';

@keyframes scrollIndicatorAnimation {
  0% {
    transform: translate3d(-50%, -300%, 0);
    opacity: 0;
  }

  50% {
    transform: translate3d(-50%, -200%, 0);
    opacity: 1;
  }

  100% {
    transform: translate3d(-50%, -100%, 0);
    opacity: 0;
  }
}

.scrollindicator {
  position: absolute;
  z-index: 98;
  left: 50vw;
  top: 600px;
  width: 36px;
  height: 18px;
  transform: translate3d(-50%, -300%, 0);
  display: none;
  flex-direction: column;
  animation-duration: 2s;
  animation-name: scrollIndicatorAnimation;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  opacity: 1;
  background-image: url('/static/images/arrow.svg');
  background-size: 100%;
  // transition: opacity 500ms ease-in-out;

  @include responsive.target(md, xl) {
    top: calc(100vh - 330px);
    width: 70px;
    height: 35px;

    @media screen and (max-height: 1000px) {
      top: calc(100vh - 230px);
    }
  }

  .main-nav--collapsed ~ & {
    opacity: 0;
  }

  img:nth-child(1) {
  }
}
