@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

// HTML example:
// <div class="radio-button-group">
//   <label for="private" class="radio-button-group__radio">
//     <i
//       class="radio-button-group__icon icon"
//       aria-hidden="true"
//     >
//       privateprofile
//     </i>

//     Privater-Einsatz

//     <input
//       type="radio"
//       name="profile"
//       id="private"
//       class="radio-button-group__input"
//       checked
//     >
//   </label>

//   <label for="company" class="radio-button-group__radio">
//     <i
//       class="radio-button-group__icon icon"
//       aria-hidden="true"
//     >
//       companyprofile
//     </i>

//     Firma-Einsatz

//     <input
//       type="radio"
//       name="profile"
//       id="company"
//       class="radio-button-group__input"
//     >
//   </label>
// </div>

.radio-button-group {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  font-weight: fonts.$fw-medium;
  font-size: 16px;
  line-height: 24px;
  gap: 9px;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
  }
}

.radio-button-group__radio {
  padding: 6px 16px;
  color: config.$primary-color;
  display: flex;
  align-items: center;
  gap: 9px;
  border: 2px solid config.$primary-color;
  border-radius: 4px;
  cursor: pointer;

  &.radio-button-group__radio--selected {
    background-color: config.$primary-color;
    color: config.$color-white;
  }

  @include responsive.target(md, xl) {
    padding: 9px 24px;
  }
}

.radio-button-group__icon {
  color: config.$primary-color;

  .radio-button-group__radio--selected & {
    color: config.$color-white;
  }
}

.radio-button-group__input {
  @include helpers.visually-hidden;
}
