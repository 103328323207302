@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.accordion-item {
  --accordion-item-header-padding-top: 8px;
  --accordion-item-header-padding-bottom: 12px;
  --accordion-item-button-font-size: 16px;
  --accordion-item-button-line-height: 1.5;
  display: block;
  width: 100%;
  font-weight: fonts.$fw-light;
  border-bottom: 2px solid config.$color-curry;

  @include responsive.target(md, xl) {
    --accordion-item-header-padding-top: 16px;
    --accordion-item-header-padding-bottom: 16px;
    --accordion-item-button-font-size: 18px;
    --accordion-item-button-line-height: 1.44;
  }

  &.accordion-item--no-border {
    border-bottom: 0;
  }
}

.accordion-item__header {
  position: relative;
}

.accordion-item__button {
  display: block;
  font-family: inherit;
  font-weight: inherit;
  width: 100%;
  font-size: var(--accordion-item-button-font-size);
  line-height: var(--accordion-item-button-line-height);
  border: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  text-align: left;
  color: config.$color-cod;
  padding-top: var(--accordion-item-header-padding-top);
  padding-bottom: var(--accordion-item-header-padding-bottom);
}

.accordion-item__arrow {
  position: absolute;
  top: var(--accordion-item-header-padding-top);
  right: 0;
  width: 16px;
  height: calc(
    #{var(--accordion-item-button-font-size)} * #{var(
        --accordion-item-button-line-height
      )}
  );
  display: block;
  background-image: url('/static/images/accordion-arrow.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: transform ease-out 0.2s;
  pointer-events: none;

  .accordion-item--expanded & {
    transform: rotate(180deg);
  }
}

.accordion-item__expandable {
  height: 0;
  overflow: hidden;
}

.accordion-item__content {
  font-size: 14px;
  line-height: 1.28;
  color: config.$color-cod;
  padding-top: 0;
  padding-bottom: 24px;

  @include responsive.target(md, xl) {
    font-size: 16px;
    line-height: 1.375;
  }
}
