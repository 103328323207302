@use 'scss/config';
@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.language-switch {
  position: fixed;
  z-index: config.$z-index-language-switch;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: #{(1195px + 16px + 16px)};
  transform: translateX(-50%);
  height: 0;
}

.language-switch__opener {
  position: absolute;
  right: 56px;
  top: #{config.$header-height--mobile + 10px};
  cursor: pointer;
  background: config.$color-mercury;
  border-radius: 4px;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 7px;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.2px;
  text-transform: uppercase;

  @include responsive.target(lg, xl) {
    top: #{config.$header-height--desktop + 10px};
    right: 122px;
    padding: 7px 10px;
  }

  @include responsive.target(xs, md) {
    @include helpers.headerTransition;

    .header--small ~ .language-switch & {
      transform: translateY(-30px);
    }

    .navigating-with-keyboard & {
      transition: none;
    }
  }

  @media (max-width: 350px) {
    transform: translateY(15px);

    .header--small ~ .language-switch & {
      transform: translateY(-15px);
    }
  }
}

.language-switch__closer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  pointer-events: none;
  display: none;

  .language-switch--open & {
    pointer-events: initial;
    display: block;
  }
}

.language-switch__list {
  position: absolute;
  right: 56px;
  top: #{config.$header-height--mobile + 10px};
  padding: 10px;
  background: config.$color-mercury;
  z-index: 200;

  display: none;

  .language-switch--open & {
    display: block;
  }

  @include responsive.target(lg, xl) {
    top: #{config.$header-height--desktop + 10px};
    right: 122px;
  }

  @media (max-width: 350px) {
    transform: translateY(15px);

    .header--small ~ .main-nav & {
      transform: translateY(-15px);
    }
  }
}

.language-switch__item {
  position: relative;
  border-bottom: 2px solid config.$color-silver;
  min-width: 120px;
  line-height: 24px;
  padding: 9px 0 7px 34px;
  font-size: 18px;
  color: config.$color-cod;
  letter-spacing: -0.2px;

  &:last-child {
    border-bottom: none;
  }
}

.language-link {
  color: inherit;
  text-decoration: none;
}

.language-link__checkmark {
  font-family: config.$font-Icons;
  position: absolute;
  left: 0;
  top: 7px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-size: 16px;
  display: none;

  .language-switch__item--active & {
    display: block;
  }
}

@include responsive.target(lg, xl) {
  .language-switch {
    .language-switch__list,
    .language-switch__opener {
      transition-property: transform;
      transition-duration: 300ms;
      transition-delay: 0ms;
      transition-timing-function: ease-in-out;

      .navigating-with-keyboard & {
        transition: none;
      }
    }
  }

  .main-nav:not(.main-nav--collapsed) + .language-switch {
    .language-switch__list,
    .language-switch__opener {
      transform: translateX(-180px);
    }
  }
}
