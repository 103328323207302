@use 'scss/config';
@use 'libs/styles/responsive';

.list-filters {
}

.list-filters__toolbar {
  margin-bottom: 16px;
}

.list-filters__expandable {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.list-filters__filters {
  position: relative;
  padding-top: 18px;

  &::before {
    position: absolute;
    top: 0;
    height: 2px;
    background-color: config.$color-stone;
    content: '';
    display: block;
    width: 100%;
  }

  @include responsive.target(md, xl) {
    padding-top: 32px;
  }
}

.list-filters__date-range {
  margin-bottom: 24px;

  @include responsive.target(md, xl) {
    margin-bottom: 32px;
  }
}

.list-filters__checkboxes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;
}

.list-filters__actions {
  display: flex;
  justify-content: space-between;

  @include responsive.target(md, xl) {
    justify-content: flex-end;
    gap: 16px;
  }
}
