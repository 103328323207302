@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.success_story_detail {
  .image_box__success-bar {
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    top: 36px;
    left: 0;
    width: 80%;
    height: 32px;
    background-color: config.$color-curry;

    @include responsive.target(md, xl) {
      left: -43px;
      width: 280px;
      height: 47px;
    }

    .text {
      color: config.$color-white;
    }

    .icon-box {
      border-radius: 50%;
      border: 2px solid config.$color-white;
      width: 24px;
      min-width: 24px;
      height: 24px;
      line-height: 20px;
      margin-left: 16px;
      margin-right: 10px;
    }

    .icon {
      font-size: 12px;
      font-weight: fonts.$fw-bold;
    }
  }
}

.gallery {
  display: flex;
  flex-direction: row;

  .icon-box {
    width: 24px;
    min-width: 24px;
    height: 24px;
    line-height: 24px;

    @include responsive.target(md, xl) {
      width: 36px;
      min-width: 36px;
      height: 36px;
      line-height: 36px;
    }
  }
}

.gallery__icon {
  &--prev {
    cursor: pointer;
    position: relative;
    place-self: center;
    margin-right: 8px;

    @include responsive.target(md, xl) {
      margin-right: 33px;
    }
  }

  &--next {
    cursor: pointer;
    position: relative;
    place-self: center;
    margin-left: 8px;

    @include responsive.target(md, xl) {
      margin-left: 33px;
    }
  }
}

.gallery__item {
  width: 100%;
}

.gallery__item__number {
  margin-top: 10px;
  font-size: 16px;
  line-height: 22px;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
  }
}

.gallery__image-box {
  background-color: config.$color-mercury;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 150%;

    @include responsive.target(md, xl) {
      padding-bottom: 66%;
    }
  }
}

.gallery__image {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  opacity: 0;
  transition-property: transform, opacity;
  transition-duration: 1000ms;
  transition-timing-function: ease-in-out;

  &--prev {
    transform: translateX(-100%);
  }

  &--next {
    transform: translateX(100%);
  }

  @include responsive.target(xs, sm) {
    align-items: center;
  }

  @include responsive.target(md, xl) {
    justify-content: center;
  }

  &--active {
    display: flex;
    opacity: 1;
    transform: translateX(0);
    transition-property: transform, opacity;
    transition-duration: 1000ms;
    transition-timing-function: ease-in-out;
  }

  img {
    @include responsive.target(xs, sm) {
      width: 100%;
    }

    @include responsive.target(md, xl) {
      height: 100%;
    }
  }
}

.gallery__item__image {
  background-position: center;
  max-height: 100%;
  width: 100%;
  background-repeat: no-repeat;
}
