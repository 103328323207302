@use 'scss/config';
@use 'libs/styles/responsive';

.date-range-filter {
  display: flex;
}

.date-range-filter__legend {
  margin-bottom: 4px;
  font-size: 12px;
  color: config.$color-carbon;
  line-height: 1.33;

  @include responsive.target(md, xl) {
    font-size: 14px;
    line-height: 1.4;
  }
}

.date-range-filter__separator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  width: 36px;
  flex-shrink: 0;
}

.date-range-filter__date {
  flex-grow: 1;
  flex-shrink: 1;

  @include responsive.target(md, xl) {
    width: 158px;
    flex-grow: 0;
  }
}
