@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.employees-page {
}

.employees-page__title {
}

.employees-page__filter-section {
  margin-bottom: 16px;

  @include responsive.target(md, xl) {
    margin-bottom: 32px;
  }
}

.employees-page__table {
  margin-bottom: 8px;
}

.employees-page__fakebutton {
  position: absolute;
  left: -99999px;
}
