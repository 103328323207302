@use 'libs/styles/responsive';

.registration-success {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
}

.registration-success__title {
  margin-bottom: 24px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.registration-success__text {
  margin-bottom: 24px;

  @include responsive.target(md, xl) {
    margin-bottom: 32px;
  }
}

.registration-success__next-steps {
  margin-bottom: 24px;

  @include responsive.target(md, xl) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.registration-success__action-row {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsive.target(md, xl) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.registration-success__action {
}

.registration-success__action-button {
  width: 100%;
}
