@use 'libs/styles/responsive';

.account-profile {}

.account-profile__delete-account-button {
  display: block;
  margin-bottom: 24px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}
