@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.project-steps {
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  padding-bottom: 12px;
  @include responsive.target(md, xl) {
    padding-bottom: 0;
  }
}

.project-steps__step {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  width: 90px;
  flex: 0 0 auto;

  opacity: 0.3;

  color: config.$color-black;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: fonts.$fw-light;
  line-height: normal;

  &.project-steps__step--done {
    opacity: 0.5;
    color: config.$primary-color;
    .project-card--cv & {
      color: config.$color-sand;
    }
  }
  &.project-steps__step--active {
    opacity: 1;
    color: config.$primary-color;
    font-weight: fonts.$fw-medium;
    .project-card--cv & {
      color: config.$color-sand;
    }
  }
  @include responsive.target(md, xl) {
    width: 130px;
    gap: 15px;
    font-size: 14px;
  }
}

.project-steps__step-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: config.$font-Icons;
  font-weight: fonts.$fw-regular;
  font-size: 16px;
  color: config.$color-white;
  background-color: config.$color-mercury;

  width: 34px;
  height: 34px;
  border-radius: 50%;

  .project-steps__step--done &,
  .project-steps__step--active & {
    color: config.$color-white;
    background-color: config.$primary-color;
    .project-card--cv & {
      color: config.$color-white;
      background-color: config.$color-sand;
    }
  }
  @include responsive.target(md, xl) {
    font-size: 24px;
    width: 48px;
    height: 48px;
  }
}

.project-steps__separator {
  flex: 1 0 auto;
  height: 2px;
  width: 70px;
  background-color: config.$color-mercury;
  margin-left: -28px;
  margin-right: -28px;
  margin-top: 17px;
  &.project-steps__separator--active {
    background-color: config.$primary-color;
    .project-card--cv & {
      background-color: config.$color-sand;
    }
  }
  &.project-steps__separator--done {
    background-color: config.$primary-color;
    .project-card--cv & {
      background-color: config.$color-sand;
    }
    opacity: 0.5;
  }
  @include responsive.target(md, xl) {
    margin-left: -41px;
    margin-right: -41px;
    margin-top: 23px;
  }
}
