@use 'scss/config';
@use 'libs/styles/responsive';

.datepicker-picker {
  background: config.$color-white;
  box-shadow: 0 1px 6px 0 rgba(config.$color-black, 0.22),
    0 3px 8px 0 rgba(config.$color-black, 0.12);

  @include responsive.target(md, xl) {
    padding: 16px;
  }
}

.datepicker-cell {
  font-size: 14px;
  color: config.$color-cod;

  &:hover,
  &.selected:hover,
  &.focused,
  &.selected {
    background: config.$color-btn-outline;
    border-radius: 2px;
  }
}
