@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

// ------------ project-detail

.booking-process__project-detail__nav {
  display: none;

  @include responsive.target(md, xl) {
    display: block;
    margin-bottom: 40px;
  }
}

.booking-process__project_detail__title {
  font-weight: fonts.$fw-light;
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 24px;
}

.booking-process__project-detail__factsheet-table {
  margin-bottom: 40px;
}

.booking-process__project-detail__booking-button-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 23px 0;
  width: 100vw;
  margin-bottom: 0;
  background-color: config.$color-honey;
  z-index: config.$z-index-profile-booking-banner;

  @include responsive.target(md, xl) {
    width: 99.1vw;
  }
}

.booking-process__project-detail__booking-button {
  border: none;
}

// ------------ first-page

.booking-process__1st-page__page-title {
  margin-bottom: 40px;
}

.booking-process__1st-page__participants-input-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.booking-process__1st-page__participants-input {
  width: 200px;
  padding: 10px 12px 8px;
}

.booking-process__1st-page__participants-input-label {
  margin-bottom: 0;
}

.booking-process__1st-page__title {
  margin-bottom: 24px;
}

.booking-process__1st-page__participants-hint {
  margin-bottom: 40px;
}

.booking-process__1st-page__button-wrapper {
  display: flex;
  justify-content: space-between;
}

// ------------ second-page

.booking-process__2nd-page__button-wrapper {
  display: flex;
  justify-content: space-between;
}

// ------------ third-page

.booking-process__3rd-page__text--small-margin {
  margin-bottom: 4px;
}

.booking-process__3rd-page__invitation-link {
  margin-bottom: 27px;
}

.booking-process__3rd-page__seperator {
  margin: 50px 0;
}

.booking-process__3rd-page__project-title {
  margin-bottom: 10px;
  font-weight: fonts.$fw-medium;
}

.booking-process__3rd-page__project-details {
  margin-bottom: 0px;
}

.booking-process__3rd-page__icon-link {
  margin-top: 25px;
  margin-bottom: 60px;
}

.booking-process__3rd-page__button-wrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.booking-process__3rd-page__back-button {
  transform: translateX(-40px);
}
