@use 'scss/config';
@use 'libs/styles/responsive';

.labelled-checkbox {
  display: flex;
  align-items: center;
}

.labelled-checkbox__checkbox {
  align-self: flex-start;
}

.labelled-checkbox__label {
  color: config.$color-cod;
  font-size: 16px;
  line-height: 24px;
  user-select: none;
  cursor: pointer;
}

.labelled-checkbox__label a {
  color: inherit;
}

.labelled-checkbox__hint {
  color: config.$color-smoke;
  display: block;

  @include responsive.target(md, xl) {
    display: inline;
  }
}
