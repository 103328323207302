@use 'scss/config';

.auto-complete {
}

.auto-complete-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: config.$z-index-auto-complete;
  pointer-events: none;
}

.auto-complete-dropdown-wrapper {
  display: none;
  position: absolute;
  z-index: 10;

  .auto-complete-overlay--is-visible & {
    display: block;
  }
}

.auto-complete-dropdown {
  pointer-events: all;
  background-color: config.$color-white;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  box-shadow: 0 1px 6px 0 rgb(0 0 0 / 22%), 0 3px 8px 0 rgb(0 0 0 / 12%);
  max-height: 264px;
  overflow: auto;
}

.auto-complete-dropdown__item {
}

.auto-complete-option {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  background: transparent;
  border: 0;
  font-size: 16px;
  line-height: 22px;

  .auto-complete-dropdown__item--highlighted & {
    background: config.$color-mercury;
  }
}
