@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.table-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.table-list__head {
  position: relative;
  border-top: 2px solid config.$color-stone;
  border-bottom: 2px solid config.$color-stone;
  padding-top: 12px;
  padding-bottom: 12px;
  grid-area: head;

  @include responsive.target(md, xl) {
    display: grid;
    grid-template-columns: 1fr 180px 150px 42px;
  }
}

.table-list-sortable-column {
  display: none;

  &.table-list-sortable-column--mobile {
    display: flex;
  }

  @include responsive.target(md, xl) {
    display: flex;
    justify-content: flex-start;

    &.table-list-sortable-column--mobile {
      justify-content: flex-start;
    }
  }
}

.table-list-sort-button {
  display: flex;
  font-family: inherit;
  background-color: transparent;
  color: config.$color-text-black;
  border: 0;
  padding: 0;
  cursor: pointer;

  .table-list-sortable-column--mobile & {
    display: flex;
    justify-content: space-between;
  }
}

.table-list-sort-button__text {
  font-size: 16px;
  line-height: 1.5;
  font-weight: fonts.$fw-light;

  @include responsive.target(md, xl) {
    margin-right: 6px;
  }
}

.sort-button {
  position: relative;
  width: 24px;
  height: 24px;
  display: block;
}

.sort-button__arrow {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  font-size: 20px;
  opacity: 0.4;
}

.sort-button__arrow--down {
  [data-ordering-desc] & {
    opacity: 1;
  }
}

.sort-button__arrow--up {
  [data-ordering-asc] & {
    opacity: 1;
  }
}

.table-list__row {
}

.table-list-row {
}

.table-list-row__button {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: config.$color-straw;
  color: black;
  text-decoration: unset;
  padding-top: 20px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;
  text-decoration: none;
  gap: 16px;
  border-radius: 4px;
  overflow: hidden;
  border: 0;
  text-align: left;

  .table-list-row--expandable & {
    cursor: pointer;
  }

  .table-list-row--expanded & {
    border-radius: 4px 4px 0px 0px;
  }

  .table-list-row--blocked & {
    background-color: config.$color-error-light;
  }

  .table-list-row--deleted & {
    background-color: config.$color-mercury;
  }

  @include responsive.target(md, xl) {
    padding-left: 32px;
    gap: 0;
    display: grid;
    grid-template-columns: auto 1fr 26px;
  }
}

.table-list-row__initials {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: config.$primary-color;
  color: white;
  flex-shrink: 0;

  .table-list-row--blocked & {
    background-color: config.$color-error;
  }

  .table-list-row--deleted & {
    background-color: config.$color-stone;
  }

  @include responsive.target(md, xl) {
    width: 56px;
    height: 56px;
  }
}

.table-list-row__center {
  flex-grow: 1;
  align-items: center;

  @include responsive.target(md, xl) {
    display: grid;
    grid-template-columns: 1fr auto;
  }
}

.table-list-row__top {
  @include responsive.target(md, xl) {
    flex-shrink: 0;
  }
}

.table-list-row__name {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
  font-weight: fonts.$fw-medium;

  @include responsive.target(md, xl) {
    padding-left: 32px;
  }
}

.table-list-row__bottom {
  display: flex;
  gap: 8px;
  font-weight: fonts.$fw-light;

  @include responsive.target(md, xl) {
    display: grid;
    grid-template-columns: 180px 150px;
    align-items: center;
    gap: 0;
  }
}

.table-list-row__timing {
  white-space: nowrap;

  .table-list-row--blocked &,
  .table-list-row--deleted & {
    font-weight: fonts.$fw-medium;
  }

  @include responsive.target(md, xl) {
    grid-row: 1;
  }
}

.table-list-row__cdate {
  padding-right: 8px;
  border-right: 1px solid currentColor;
  white-space: nowrap;

  @include responsive.target(md, xl) {
    border-right: 0;
  }
}

.table-list-row__arrow {
  transition: transform ease 0.2s;

  .table-list-row--expanded & {
    transform: rotate(90deg);
  }
}

.table-list-row__expandable {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

.table-list-row__content {
  padding-top: 24px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 24px;
  background-color: config.$color-straw;
  border-top: 2px solid config.$color-white;
  border-radius: 0px 0px 4px 4px;

  @include responsive.target(md, xl) {
    padding-top: 48px;
    padding-left: 120px;
    padding-right: 43px;
    padding-bottom: 48px;
  }
}

.table-list-row__section {
}

.table-list-row__section-title {
  margin-bottom: 8px;
}

.table-list-row__text {
  font-weight: fonts.$fw-light;
  max-width: 530px;
  margin-bottom: 16px;
}

.table-list-row__link {
  margin-bottom: 28px;
}

.table-list-row__hr {
  margin-top: 20px;
  margin-bottom: 20px;
  height: 2px;
  width: 100%;
  background-color: config.$color-white;

  @include responsive.target(md, xl) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.table-list-row-actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;

  @include responsive.target(md, xl) {
    flex-direction: row;
    margin-bottom: 32px;
  }
}

.table-list-row-actions__action {
  width: 100%;

  .btn {
    width: 100%;
  }

  @include responsive.target(md, xl) {
    width: auto;

    .btn {
      width: auto;
    }
  }
}

.table-list-row-social-media {
}

.table-list-row-social-media__text {
  color: config.$color-carbon;
  font-weight: fonts.$fw-light;
  font-size: 14px;
  line-height: 1.28;
  margin-bottom: 16px;

  @include responsive.target(md, xl) {
    font-size: 16px;
    line-height: 1.375;
  }
}

.table-list__no-results {
  color: config.$color-carbon;
  font-size: 16px;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 2px solid config.$color-stone;
  border-bottom: 2px solid config.$color-stone;
  padding-top: 16px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 16px;
}

.pagination__control {
  text-decoration: none;
  color: config.$color-cod;
}

.pagination__left {
  display: flex;
}

.pagination__first-page {
  margin-right: 28px;
}

.pagination__previous-page {
}

.pagination__center {
}

.pagination__current {
  font-size: 16px;
  line-height: 1.5;
  font-weight: fonts.$fw-light;
}

.pagination__right {
  display: flex;
}

.pagination__next-page {
}

.pagination__last-page {
  margin-left: 28px;
}
