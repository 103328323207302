@use 'scss/config';
@use 'libs/styles/responsive';

.about-video {
  position: relative;
  width: 90%;
  max-width: 1333px;
  margin: 80px auto;
  cursor: pointer;

  @include responsive.target(lg, xl) {
    width: 70%;
  }
}

.about-video__inner {
  position: relative;
  background-image: url('/static/images/video_thumbnail.jpg');
  background-size: cover;
  background-position: center;

  &:before {
    content: '';
    height: 0;
    display: block;
    padding-bottom: 56.25%;
  }

  &:after {
    position: absolute;
    left: calc(50% - 7px);
    top: calc(50% - 13px);
    border-radius: 10px;
    width: 300px;
    height: 160px;
    transform: translateX(-50%) translateY(-50%);
    box-shadow: 14px 12px 0 0 rgba(config.$color-black, 0.4);
    background-color: config.$color-honey;
  }

  @include responsive.target(xs) {
    &:after {
      width: 274px;
      height: 135px;
    }
  }

  @include responsive.target(md, xl) {
    &:after {
      width: 380px;
      height: 217px;
    }
  }
}

.about-video__cover {
  position: absolute;
  inset: 0;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  pointer-events: none;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.about-video--stopped {
  .about-video__cover {
    opacity: 1;
    pointer-events: auto;
  }
}

.about-video__content {
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: none;
}

#player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.about-video__title {
  margin-bottom: 9px;
  z-index: 3;
  font-size: 28px;
  color: config.$color-black;
  letter-spacing: 0;
  text-align: center;
  line-height: 58px;
}

.about-video__btn {
  z-index: 3;
  outline: none;
  transition: transform 0.2s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid config.$primary-color;
  cursor: pointer;

  @include responsive.target(lg, xl) {
    &:hover {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}

.about-video__icon {
  z-index: 3;
  color: config.$color-white;
  display: block;
  transform: translateX(3px) translateY(-2px);
}
