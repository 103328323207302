@use 'scss/config';

.input-field {
  display: inline-block;
  background: config.$color-white;
  width: 100%;
}

.input-field__helptext {
  padding: 10px 5px 0;
  font-size: 14px;
  line-height: 16px;

  li {
    margin-bottom: 5px;
  }
}

.input-field__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid config.$color-input-border-default;
    border-radius: 2px;
    box-sizing: border-box;
    transition-property: border-color, border-width;
    transition-duration: 70ms;
    transition-timing-function: ease-in-out;
    pointer-events: none;
  }

  .form-field:hover & {
    &::before {
      border-color: config.$color-input-border-hover;
    }
  }

  .form-field--error & {
    &::before {
      border-color: config.$color-form-error-text;
    }
  }

  .input-field--focus:hover &,
  .input-field--focus & {
    &::before {
      border-color: config.$color-input-border-focus;
      border-width: 2px;
    }
  }
}

.input-field__icon {
  border: 0;
  background: transparent;
  padding: 7px 11px;
  display: flex;
  align-items: flex-end;
}

.input-field__label {
  font-size: 16px;
  color: config.$color-input-label;
  line-height: 22px;
  position: absolute;
  left: 12px;
  top: 20px;
  pointer-events: none;
  user-select: none;
  transform-origin: 0 100%;
  transition-property: transform;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;

  .input-field--filled &,
  .input-field--focus & {
    transform: translateY(-22px) scale(0.75);
    white-space: nowrap;
  }
}

.input-field__input {
  display: block;
  width: 100%;
  font-size: 16px;
  color: config.$color-cod;
  line-height: 22px;
  outline: 0;
  border: 0;
  padding: 20px 12px 6px;
  height: 48px;
  resize: vertical;
  overflow: hidden;
}

textarea.input-field__input {
  line-height: 1.4;
}
