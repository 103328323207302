@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.success_story_hero_list {
  margin-bottom: 40px;

  @include responsive.target(md, xl) {
    margin-bottom: 50px;
  }
}

li.success_story_hero {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-bottom: 40px;
}

.success_story_hero__name {
  display: flex;
  align-items: center;
  min-height: 80px;
  font-size: 28px;
  line-height: 34px;
  font-weight: fonts.$fw-medium;
  padding-bottom: 20px;

  @include responsive.target(md, xl) {
    min-height: 90px;
    font-size: 28px;
    line-height: 34px;
  }
}

.success_story_hero__content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include responsive.target(md, xl) {
    flex-direction: row;
  }
}

.success_story_hero__media_wrapper {
  width: 100%;

  @include responsive.target(md, xl) {
    width: 50%;
  }
}

.success_story_hero__image_box_wrapper {
  position: relative;

  @include responsive.target(xs, sm) {
    padding-bottom: 130.555555556%;
    margin-bottom: 20px;
  }

  @include responsive.target(md, xl) {
    width: 100%;
    max-width: 879px;
    margin: 0;
  }
}

.success_story_hero__image_box_wrapper__inner {
  @include responsive.target(md, xl) {
    padding-bottom: 63.708759954%;
    width: 100%;
  }
}

.success_story_hero__image_box {
  @include responsive.target(xs, sm) {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 20px);
    height: calc(100% - 20px);

    &:before {
      content: '';
      position: absolute;
      right: -20px;
      bottom: -20px;
      height: 100%;
      width: 50%;
    }
  }

  @include responsive.target(md, xl) {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 50px);
    height: calc(100% - 20px);

    &:before {
      content: '';
      position: absolute;
      right: -50px;
      bottom: -20px;
      height: 100%;
      width: 100%;
    }
  }
  &--fern {
    &:before {
      background-color: config.$color-fern;
    }
  }

  &--sand {
    &:before {
      background-color: config.$color-sand;
    }
  }

  &--terracotta {
    &:before {
      background-color: config.$color-terracotta;
    }
  }

  .image_box__image {
    &--mobile {
      display: block;
      width: 100%;
      height: 100%;
      background-size: 100%;
      background-position: center;

      &:after {
        content: '';
        height: 130px;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        transform: rotate(-180deg);
        background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 12%,
          rgba(0, 0, 0, 0.5) 68%,
          rgba(0, 0, 0, 0.75) 100%
        );
      }
    }

    &--desktop {
      display: none;
    }

    @include responsive.target(md, xl) {
      &--mobile {
        display: none;
      }

      &--desktop {
        display: block;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
      }
    }
  }
}

.player_box {
  .play_button {
    position: relative;
    top: -50%;
    transform: translateY(-50%);
    margin: auto;
    background-color: config.$color-black;
    opacity: 0.6;
    width: 70px;
    height: 50px;
    line-height: 50px;
    border-radius: 20%;
    cursor: pointer;

    &--hidden {
      display: none;
    }

    .icon {
      color: config.$color-white;
      font-size: 25px;
    }
  }
}

.success_story_hero__video {
  position: relative;
  width: 100%;
  height: 100%;
}

.success_story_hero__preview {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &--hidden {
    display: none;
  }
}

.success_story_hero__preview_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.success_story_hero__category {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 10px;
  bottom: 10px;
  width: 36px;

  @include responsive.target(md, xl) {
    top: 32px;
    right: -42px;
  }
}

.success_story_hero__category__text {
  display: none;

  @include responsive.target(md) {
    font-size: 18px;
  }

  @include responsive.target(md, xl) {
    display: block;
    font-weight: fonts.$fw-regular;
    transform: rotate(-270deg);
    color: config.$color-white;
    letter-spacing: 0;
    line-height: 25px;
    margin-top: 13px;
  }

  @include responsive.target(lg, xl) {
    font-size: 22px;
  }
}

.success_story_hero__text_wrapper {
  width: 100%;

  @include responsive.target(md, xl) {
    width: 50%;
    padding: 0 20px;
  }

  .btn--filled {
    background-color: config.$color-curry;
    color: config.$color-white;

    @media (hover: hover),
      screen and (-ms-high-contrast: active),
      (-ms-high-contrast: none) {
      &:hover {
        background-color: config.$color-honey;
        color: config.$color-white;
      }
    }
  }
}

.success_story_hero__description {
  @include responsive.target(md, xl) {
    max-width: 70%;
  }
}

.success_story_hero__btn {
  place-self: center;
}
