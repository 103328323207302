@use 'scss/config';
@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

.search-toggler {
  position: fixed;
  z-index: config.$z-index-search-toggler;
  top: 0;
  left: 50%;
  width: 100%;
  max-width: #{(1195px + 16px + 16px)};
  transform: translateX(-50%);
  height: 0;
  display: block;
}

.search-toggler__opener {
  position: absolute;
  top: #{config.$header-height--mobile + 10px};
  right: 105px;
  cursor: pointer;
  background: config.$color-mercury;
  border-radius: 4px;
  outline: none;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 7px;
  font-size: 18px;
  line-height: 24px;

  @include responsive.target(xs, md) {
    @include helpers.headerTransition;

    .header--small ~ .search-toggler & {
      transform: translateY(-30px);
    }

    .navigating-with-keyboard & {
      transition: none;
    }
  }

  @media (max-width: 350px) {
    transform: translateY(15px);

    .header--small ~ .search-toggler & {
      transform: translateY(-15px);
    }
  }

  .search-toggler--active & {
    display: none;
  }

  @include responsive.target(lg, xl) {
    top: #{config.$header-height--desktop + 10px};
    right: 178px;
    padding: 7px 10px;
  }
}

.search-toggler__closer {
  position: absolute;
  top: #{config.$header-height--mobile + 10px};
  right: 105px;
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
  flex-direction: row;
  align-items: center;
  padding: 4px 7px;
  font-size: 18px;
  line-height: 22px;
  display: none;

  @include responsive.target(xs, md) {
    @include helpers.headerTransition;

    .header--small ~ .search-toggler & {
      transform: translateY(-30px);
    }

    .navigating-with-keyboard & {
      transition: none;
    }
  }

  @media (max-width: 350px) {
    transform: translateY(15px);

    .header--small ~ .search-toggler & {
      transform: translateY(-15px);
    }
  }

  @include responsive.target(lg, xl) {
    top: #{config.$header-height--desktop + 10px};
    right: 178px;
    padding: 7px 10px;
  }

  .search-toggler--active & {
    display: flex;
  }
}

.search__submit,
.search__reset {
  cursor: pointer;
}
