@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/helpers';
@use 'libs/styles/responsive';

textarea {
  font-family: config.$font-Frutiger;
}

.form {
  margin-bottom: 50px;

  &.form--no-margin {
    margin-bottom: 0;
  }
}

.form__group {
  margin-bottom: 40px;
}

.form__column {
  margin-right: 10px;
  flex: 1 1 100%;

  &:last-child {
    margin-right: 0;
  }

  &--separator {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &--for-textarea {
    margin-bottom: 20px;

    @include responsive.target(md, xl) {
      margin-bottom: 27px;
    }
  }

  @include responsive.target(md, xl) {
    margin-right: 16px;
  }
}

.form__footnote {
  margin-top: 1em;
  margin-bottom: 2.5em;

  .recurring_project_signup & {
    margin-top: 2em;
    margin-bottom: 1em;
  }
}

.form__footnote__text {
  line-height: 1.25;
  font-size: 16px;
}

.form__row {
  display: flex;
  flex-direction: row;
  max-width: 418px;
  margin-bottom: 10px;

  &.form__row--wider {
    max-width: 532px;
  }

  &.form__row--full {
    max-width: none;
  }

  @include responsive.target(md, xl) {
    margin-bottom: 16px;
  }
}

.form__separator {
  max-width: 418px;
  margin: 50px 0;
  border: 2px solid config.$color-silver;

  &.form__separator--wider {
    max-width: 532px;
  }
}

.form__row__label {
  display: block;
  font-size: 16px;
  font-weight: fonts.$fw-regular;
  line-height: 1.444;
  margin-bottom: 10px;
}

.form__action-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  &--column {
    flex-direction: column;
    align-items: flex-start;
  }

  @include responsive.target(xs, sm) {
    .btn {
      margin-bottom: 20px;
    }
  }
}

.form__action-row--reverse {
  flex-direction: row-reverse;
}

.form__action-row__row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

/*******************************\
*         Field errors          *
\*******************************/

.errornote {
  font-size: 12px;
  color: config.$color-cod;
  line-height: 16px;
  padding: 12px 16px;
  background: config.$color-form-error-bg;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.errornote__icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: config.$color-form-error-text;
  text-align: center;
  color: config.$color-white;
  margin-right: 16px;
  flex-shrink: 0;
}

.form-field__error_list {
  margin-top: 5px;
}

.form-field__error {
  font-size: 14px;
  color: config.$color-form-error-text;
  line-height: 16px;
  background: config.$color-form-error-bg;
  padding: 2px 5px;
  display: flex;
  flex-direction: row;
}

.form-field__error-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: config.$color-form-error-text;
  text-align: center;
  color: config.$color-white;
  margin-right: 7px;
  flex-shrink: 0;
}

/*******************************\
*          Form field           *
\*******************************/

.form-field__label {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  justify-content: space-between;
}

.form-field__help {
  justify-self: flex-end;
}
