@use 'scss/config';

.partner-projects {
}

.partner-projects__list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.partner-projects__item {
}

.partner-projects__no-projects {
  font-size: 16px;
  color: config.$color-carbon;
}
