@use 'scss/config';
@use 'libs/styles/fonts';
@use 'libs/styles/responsive';

.profile {
  display: flex;
  flex-direction: column;

  @include responsive.target(md, xl) {
    align-items: stretch;
    flex-direction: row;
  }
}

.profile-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  &.profile-header--desktop {
    display: none;
  }

  @include responsive.target(md, xl) {
    &.profile-header--mobile {
      display: none;
    }

    &.profile-header--desktop {
      display: block;
    }
  }
}

.profile-title {
  margin-bottom: 32px;

  &.profile-title--desktop {
    display: none;
  }

  @include responsive.target(md, xl) {
    margin-bottom: 56px;

    &.profile-title--mobile {
      display: none;
    }

    &.profile-title--desktop {
      display: block;
    }
  }
}

.profile-header__settings {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: config.$color-curry;
}

.settings__icon {
  .icon {
    font-size: 25px;
    margin-right: 5px;
  }
}

.settings__link {
  color: config.$color-curry;
  text-decoration: none;
  font-weight: fonts.$fw-medium;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;

  @include responsive.target(md, xl) {
    font-size: 22px;
  }
}

.profile__navigation {
  width: 100%;
  flex-shrink: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: config.$z-index-profile-navigation;
  overflow: auto;
  background-color: config.$color-white;
  display: none;

  &.profile__navigation--open {
    display: block;
  }

  @include responsive.target(md, xl) {
    display: block;
    position: static;
    top: auto;
    left: auto;
    bottom: auto;
    overflow: visible;
    padding-right: 16px;
    width: 272px;
  }
}

.profile__main {
  flex-grow: 1;

  @include responsive.target(md, xl) {
    padding-top: 0;
    padding-left: 72px;
    border-left: 1px solid config.$color-mercury;
  }
}

.profile__back-to-navigation {
  margin-top: -56px;
  margin-bottom: 56px;

  @include responsive.target(md, xl) {
    display: none;
  }
}

.profile-back-button {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: config.$color-cod;
  line-height: 30px;
  font-weight: fonts.$fw-light;
  cursor: pointer;
}

.profile-back-button__icon {
  color: config.$color-curry;
  margin-top: -2px;
  margin-left: -4px;
}

.profile__breadcrumbs {
  margin-bottom: 32px;

  @include responsive.target(md, xl) {
    margin-bottom: 40px;
  }
}

.profile__content {
}

.profile__invitation {
  margin-bottom: 32px;
}

.project-countdown {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @include responsive.target(md, xl) {
    flex-direction: row;
    margin-bottom: 30px;
  }
}

.profile-text {
  font-size: 16px;
  color: config.$color-cod;
  line-height: 22px;

  @include responsive.target(md, xl) {
    font-size: 18px;
    line-height: 26px;
    padding-right: 5px;
  }
}

.employee {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .text {
    white-space: nowrap;
  }
}

.employee-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 12px;

  .btn {
    margin-left: 20px;
    margin-bottom: 8px;
  }
}
